import React, { useState, useEffect, useRef } from 'react';
import HeaderMainPage, { newCampaign } from '../../header/HeaderMainPage';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { IoArrowBack } from "react-icons/io5";
import CampaignSummary from "./campaignSummary";
import { HiDotsVertical } from "react-icons/hi";
const StarIcon = ({ filled }) => (
  <svg className={`w-6 h-6 ${filled ? 'text-yellow-400' : 'text-gray-300'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
  </svg>
);

// Define column widths
const columnWidths = {
  checkbox: '50px',
  star: '50px',
  campaign: '300px',
  deliveryDate: '150px',
  coreproduct: '150px',
  actions: '100px',
  value: '100px',
};

const MyCampaign = () => {
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [activeTab, setActiveTab] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [anySelected, setAnySelected] = useState(false); // New state to track if any checkbox is selected
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const roboman_api = process.env.REACT_APP_ROBOMAN_API;
  const campaignSelectionRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (campaignSelectionRef.current && !campaignSelectionRef.current.contains(event.target)) {
        setOpenDropdown(null);
      }
    };

    const handleScroll = () => {
      setOpenDropdown(null); // Close dropdown when scrolling
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener("wheel", handleScroll);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener("wheel", handleScroll);
    };
  }, []);
  

  // Function to update column width if needed in the future
  const updateColumnWidth = (column, width) => {
    columnWidths[column] = width;
    // Force re-render
    setCampaigns([...campaigns]);
  };

  // New function to fetch user data from API
  const fetchCampaignDataFromAPI = async (access_token) => {
    try {
      const response = await fetch(`${roboman_api}/campaign/personal`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': access_token,
        },
      });

      if (!response.ok) {
        console.error(`HTTP error! status: ${response.status}`);
        return []
      }
      const data = await response.json();
      return data;

    } catch (error) {
      console.error('Error fetching user campaign data:', error);
      return null;
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    // Format time
    const time = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false // Use 24-hour format
    });
    
    // Format date
    const formattedDate = date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    });
    
    // Combine time and date
    return `${time} ${formattedDate}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      // First, try to load data from localStorage
      const access_token = localStorage.getItem('access_token')
      const userCampaign = await fetchCampaignDataFromAPI(access_token);
      if (userCampaign) {
        // If API fetch is successful, use the data
        // localStorage.setItem('campaign_data', userCampaign);

        const staticCampaigns = userCampaign.map((item, index) => ({
          id: item.campaign_id,
          name: item.campaign_name,
          deliveryDate: formatDate(item.created_at),
          isStarred: false,
          isSelected: false,
          campaigns_id: item.campaign_id,
          campaigns_info: item.campaign_info,
          email_format_info: item.email_format,
          must_have_info: item.campaign_contact?.campaign_email || "",
          stats: item.stats

        }));
        setCampaigns(staticCampaigns);
        // console.log(staticCampaigns)

      }
    };

    fetchData();

  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleStarClick = (id) => {
    setCampaigns(campaigns.map(campaign => 
      campaign.id === id ? { ...campaign, isStarred: !campaign.isStarred } : campaign
    ));
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setCampaigns(campaigns.map(campaign => ({ ...campaign, isSelected: !selectAll })));
    setAnySelected(!selectAll); // Update anySelected state
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // const handleDropdownToggle = (id) => {
  //   setOpenDropdown(openDropdown === id ? null : id);
  // };

  const handleDelete = async (id) => {
    setOpenDropdown(null);
    try {
      // Make the DELETE request to the API
      const response = await fetch(`${roboman_api}/campaign/${id}/delete`, {
        method: 'DELETE',
        headers: {
          'accept': 'application/json',
          'Authorization': localStorage.getItem('access_token'), // Replace 'code' with your actual token
        },
      });

      if (!response.ok) {
        // Log the error and show SweetAlert error message
        console.error('Failed to delete campaign', response);
        
        // Show error alert using SweetAlert
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'There was a problem deleting the campaign. Please try again later.',
          confirmButtonText: 'OK',
        });

        return; // Return to stop further execution
      }

      // If the delete is successful, update the UI
      setCampaigns(campaigns.filter(campaign => campaign.id !== id));
      

      // Show success alert using SweetAlert
      Swal.fire({
        icon: 'success',
        title: 'Deleted!',
        text: 'Your campaign has been deleted successfully.',
        confirmButtonText: 'OK',
      });

    } catch (error) {
      console.error('Error deleting campaign:', error);
      
      // Show error alert using SweetAlert
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'There was a problem deleting the campaign. Please try again later.',
        confirmButtonText: 'OK',
      });
    }
  };

  // New function to handle Details button click
  const handleDetails = (campaign) => {
    // Save the selected campaign name in local storage
    localStorage.setItem('campaign_name', campaign.name);
    localStorage.setItem('campaign_id', campaign.id);  // Save the campaign id
    localStorage.setItem('campaignState', 'Cancel');
    // localStorage.setItem('core_service', campaign.campaigns_info.core_service)
    // localStorage.setItem('key_benefits', campaign.campaigns_info.key_benefits)
    // localStorage.setItem('problem_solved', campaign.campaigns_info.problem_solved)
    // localStorage.setItem('campaign_info',JSON.stringify(campaign.campaigns_info))
    // localStorage.setItem('campaignOutputFormat', campaign.email_format_info)
    // const must_have_info = `My contact email is ${campaign.must_have_info}`
    // localStorage.setItem('campaignMustHaveInfo', must_have_info)
    // Navigate to the details page
    navigate(`/email-status`);
  };

  const handleCheckboxChange = (id) => {
    setCampaigns(campaigns.map(campaign => 
      campaign.id === id ? { ...campaign, isSelected: !campaign.isSelected } : campaign
    ));
    const newAnySelected = campaigns.some(campaign => campaign.id === id ? !campaign.isSelected : campaign.isSelected);
    setAnySelected(newAnySelected);
  };

  const handleDeleteSelected = () => {
    setCampaigns(campaigns.filter(campaign => !campaign.isSelected));
    setAnySelected(false);
  };

  const handleBack = () => {
    navigate('/dashboard');
  };

  const filteredCampaigns = campaigns.filter(campaign => {
    if (activeTab === 'All') return true;
    if (activeTab === 'Starred') return campaign.isStarred;
    return true; // For other tabs, show all for now
  }).filter(campaign => 
      (campaign.name && campaign.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (campaign.library && campaign.library.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  const handleDropdownToggle = (campaignId, event) => {
    if (openDropdown === campaignId) {
      setOpenDropdown(null);
    } else {
      const rect = event.currentTarget.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX - 30,
      });
      setOpenDropdown(campaignId);
    }
  };


  return (
    <div className="bg-gray-100 min-h-screen">
      <HeaderMainPage />
      <div className="max-w-[1600px] mx-auto px-4 sm:px-6 lg:px-8 mt-[80px] shadow-md bg-white rounded-md">
        <div className="flex justify-between items-center mb-6 p-3">
          <div className="flex items-center gap-4">
            <button 
              onClick={handleBack} 
              className="p-2 text-black bg-gray-200 rounded-full hover:bg-gray-300"
              aria-label="Back"
            >
              <IoArrowBack size={20} />
            </button>
            <h1 className="text-3xl font-bold text-gray-900">List of Campaign</h1>
          </div>
          
          <button className="bg-[#223F9E] text-white px-4 py-2 font-semibold rounded-full hover:bg-blue-700 transition duration-300" onClick={() => newCampaign(navigate)}>
            + Create New Campaign
          </button>
        </div>

        <div className="border-b border-gray-200 mb-6">
          {['All', 'Starred', 'Draft', 'Sent', 'Scheduled', 'Archived'].map(tab => (
            <button
              key={tab}
              onClick={() => handleTabClick(tab)}
              className={`px-4 py-2 mr-4 focus:outline-none ${
                activeTab === tab
                  ? 'border-b-2 border-blue-500 text-blue-600'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              {tab}
            </button>
          ))}
        </div>

        <div className="flex items-center mb-6">
          <div className="flex items-center ml-6 mr-4">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
              className="form-checkbox h-5 w-5 text-orange-500 rounded border-gray-300 focus:ring-orange-500"
            />
          </div>
          <div className="relative flex-grow max-w-xs ml-10">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
              </svg>
            </div>
          </div>
          <button
            className={`bg-red-600 text-white px-4 py-2 font-semibold rounded-md hover:bg-red-700 transition duration-300 ml-4 ${anySelected ? '' : 'opacity-50 cursor-not-allowed'}`}
            onClick={handleDeleteSelected}
            disabled={!anySelected}
          >
            Delete
          </button>
        </div>

        <div className="bg-white shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <div className="max-h-[calc(90vh-400px)] overflow-y-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 sticky top-0 z z-50">
                <tr>
                  <th style={{ width: columnWidths.checkbox }}></th>
                  <th style={{ width: columnWidths.star }}></th>
                  <th style={{ width: columnWidths.campaign }} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Campaign</th>
                  <th style={{ width: columnWidths.deliveryDate }} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created Date</th>
                  <th style={{ width: columnWidths.coreproduct }} className= "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Core Product/Service</th>
                  <th style={{ width: columnWidths.value }} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Prospect</th>
                  <th style={{ width: columnWidths.value }} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sent</th>
                  <th style={{ width: columnWidths.value }} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Open</th>
                  <th style={{ width: columnWidths.value }} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Reply</th>
                  <th style={{ width: columnWidths.value }} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Interested</th>
                  <th style={{ width: columnWidths.actions }} className="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 ">
                {filteredCampaigns === null || filteredCampaigns.length === 0 ? (
                  <tr>
                    <td colSpan="11" className="px-6 py-4 text-center text-gray-900 text-xl">
                      Loading...
                    </td>
                  </tr>
                ) : (
                  filteredCampaigns.map(campaign => (
                    <tr key={campaign.id}>
                      <td className="px-6 py-4 whitespace-nowrap" style={{ width: columnWidths.checkbox }}>
                        <input
                          type="checkbox"
                          checked={campaign.isSelected}
                          onChange={() => handleCheckboxChange(campaign.id)}
                          className="form-checkbox h-5 w-5 text-orange-500 rounded border-gray-300 focus:ring-orange-500"
                        />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap" style={{ width: columnWidths.star }}>
                        <button onClick={() => handleStarClick(campaign.id)} className="focus:outline-none">
                          <StarIcon filled={campaign.isStarred} />
                        </button>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap" style={{ width: columnWidths.campaign }}>
                        <div>
                          <div className="text-sm font-medium text-gray-900">
                            <button
                              className="text-gray-900 hover:underline focus:outline-none"
                              onClick={() => handleDetails(campaign)}
                            >
                              {campaign.name}
                            </button>
                          </div>
                          <div className="text-sm text-gray-500">{campaign.library}</div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500" style={{ width: columnWidths.deliveryDate }}>
                        {campaign.deliveryDate}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 truncate max-w-xs" style={{ width: columnWidths.coreproduct }} title={campaign.campaigns_info.core_service}>
                        {campaign.campaigns_info.core_service}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500" style={{ width: columnWidths.value }}>
                        {campaign.stats.prospects_count}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500" style={{ width: columnWidths.value }}>
                        {campaign.stats.sent.count}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500" style={{ width: columnWidths.value }}>
                        {campaign.stats.opened.count}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500" style={{ width: columnWidths.value }}>
                        {campaign.stats.replied.count}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500" style={{ width: columnWidths.value }}>
                        {campaign.stats.interested.count}
                      </td>
                      <td className="px-1 py-4 whitespace-nowrap text-center text-sm font-medium" style={{ width: columnWidths.actions }}>
                        <div className="relative" ref={campaignSelectionRef}>
                        <button
                          onClick={(event) => handleDropdownToggle(campaign.id, event)}
                          className="text-gray-400 hover:text-gray-500 focus:outline-none"
                        >
                          <HiDotsVertical className="w-6 h-6" />
                        </button>
                          
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* {openDropdown && (
          <div className="origin-top-right absolute right-0 mt-2 w-[120px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40">
            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <button
                onMouseDown={() => setSelectedCampaign(campaign.id)}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
              >
                Summary
              </button>
              <button
                onMouseDown={() => handleDetails(campaign)}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
              >
                Details
              </button>
              <button
                onClick={() => handleDelete(campaign.id)}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
              >
                Delete
              </button>
            </div>
          </div>
        )} */}

        {/* Move dropdown outside of table row */}
        {openDropdown && (
          <div 
            className="absolute z-50 bg-white shadow-lg rounded-md ring-1 ring-black ring-opacity-5 mt-2 w-[120px]"
            style={{
              top: dropdownPosition.top,
              left: dropdownPosition.left,
            }}
          >
            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <button
                onMouseDown={() => setSelectedCampaign(openDropdown)}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              >
                Summary
              </button>
              <button
                onMouseDown={() => handleDetails(filteredCampaigns.find(c => c.id === openDropdown))}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              >
                Details
              </button>
              <button
                onClick={() => handleDelete(openDropdown)}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              >
                Delete
              </button>
            </div>
          </div>
        )}



        {selectedCampaign && (
          <CampaignSummary
            campaignId={selectedCampaign}
            onClose={() => setSelectedCampaign(null)}
          />
        )}


      </div>
    </div>
  );
};

export default MyCampaign;
