import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderMainPage from '../../header/HeaderMainPage';
import { FaReply, FaSearch } from "react-icons/fa";
import Swal from "sweetalert2";
import { FaArrowLeft, FaInfoCircle } from 'react-icons/fa';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ color: [] }, { background: [] }],
    ["blockquote", "code-block"],
    ["link", "image"],
    ["clean"],
  ],
};

// Define column widths
const columnWidths = {
    star: '50px',
    email: '150px',
    date: '100px',
};

const ReplyBox = ({ replyHistory, handleCloseRepSuggest }) => {
    const lastReply = replyHistory.at(-1); // Get last item
    const subject = lastReply?.suggested_response?.subject || "";
    const body = lastReply?.suggested_response?.body || "";
    const explanation = lastReply?.explanation || "No Explanation";

    // State for showing explanation box
    // State for showing explanation box
    const [showExplanation, setShowExplanation] = useState(false);

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-500 bg-opacity-50 animate-fadeIn">
            <div className="relative flex">
                {/* Main Reply Box */}
                <div className="bg-white p-4 rounded-md shadow-lg border w-[600px] animate-fadeInUp relative">
                    {/* Subject Input */}
                    <div className="mb-2">
                        <label className="block text-md font-medium text-gray-700 mb-1">Subject:</label>
                        <input
                            type="text"
                            value={subject}
                            readOnly
                            className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-600 bg-gray-100"
                        />
                    </div>

                    {/* Message Input */}
                    <div className="mb-2">
                        <label className="block text-md font-medium text-gray-700 mb-1">Message:</label>
                        <ReactQuill
                            theme="snow"
                            value={body}
                            modules={{ toolbar: false }}
                            readOnly={true}
                            className="h-[400px] rounded-md mb-[10px] bg-gray-100"
                        />
                    </div>

                    {/* Buttons: Close & Star */}
                    <div className="flex justify-end gap-2 mt-2">
                        <button
                            className="bg-gray-300 w-30 text-black px-4 py-2 rounded-full hover:bg-gray-400"
                            onClick={handleCloseRepSuggest}
                        >
                            Close
                        </button>

                        {/* Star/Explain Button */}
                        <button
                            className="px-4 py-2 rounded-full border border-gray-500 text-gray-700 shadow-md bg-white hover:bg-gray-700 hover:text-white transition-all"
                            // onClick={() => setShowExplanation(!showExplanation)}
                            onMouseEnter={() => setShowExplanation(true)}
                            onMouseLeave={() => setShowExplanation(false)}
                        >
                            Explain ✨
                        </button>
                    </div>
                </div>

                {/* Explanation Box - Positioned to the right */}
                {showExplanation && (
                    <div className="absolute top-0 right-[-420px] w-[400px] p-3 border border-gray-400 rounded-lg shadow-md bg-white animate-fadeIn transition-opacity">
                        <label className="block text-md font-medium text-gray-700 mb-1">Explanation ✨</label>
                        <textarea
                            value={explanation}
                            readOnly
                            className="w-full h-[500px] border border-gray-300 rounded-md p-2 bg-gray-100 resize-none"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

const MailboxContent = ({ activeTab }) => {

    const roboman_api = process.env.REACT_APP_ROBOMAN_API;
    const campaignId = localStorage.getItem("campaign_id");
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [replyBox, setReplyBox] = useState(false);
    const [replySubject, setReplySubject] = useState("");
    const [replyContent, setReplyContent] = useState("");
    const [sentMails, setSentMails] = useState([]);
    const [replyBoxSuggest, setReplyBoxSuggest] = useState(false); 

    const [selected_internal_id, setSelectedInternalID] = useState(null);
    const [replyHistory, setReplyHistory] = useState([]);
    const [selectedMail, setSelectedMail] = useState([]);
    const [linkedInMessages, setLinkedInMessage] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const authToken = localStorage.getItem("access_token");

    const fetchSentMails = async () => {
        try {
            const response = await fetch(`${roboman_api}/campaign/${campaignId}/emails/sent-mails`, {
                method: "GET",
                headers: {
                    "accept": "application/json",
                    "Authorization": authToken,
                },
            });

            if (!response.ok) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to fetch sent emails.",
                    confirmButtonText: "OK"
                });
                return; // Exit the function early
            }

            const data = await response.json();
            // console.log("Fetched sent mails:", data);
            setSentMails(data);
        } catch (error) {
            Swal.fire({
                title: "Error!",
                text: error.message,
                icon: "error",
                confirmButtonText: "Okay"
            });
        }
    };

    const fetchSentLinkedin = async () => {
        try {
            const response = await fetch(`${roboman_api}/campaign/${campaignId}/linkedin/sent-messages`, {
                method: "GET",
                headers: {
                    "accept": "application/json",
                    "Authorization": authToken,
                },
            });

            if (!response.ok) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to fetch sent LinkedIn messages.",
                    confirmButtonText: "OK"
                });
                return;
            }

            const data = await response.json();
            setLinkedInMessage(data);
        } catch (error) {
            Swal.fire({
                title: "Error!",
                text: error.message,
                icon: "error",
                confirmButtonText: "Okay"
            });
        }
    };

    useEffect(() => {
        console.log("Switch to tab:", activeTab)
        if (activeTab === "mail") {
            fetchSentMails();
        } else if (activeTab === "linkedin") {
            fetchSentLinkedin();
        }
    }, [activeTab])

    const handleMailReplyHistory = async (sent_email_id) => {
        try {
            const response = await fetch(`${roboman_api}/campaign/emails/${sent_email_id}/reply-mails`,
                {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Authorization": authToken, // Replace with your actual token
                    },
                }
            );
    
            if (!response.ok) {
                console.error(`Error: ${response.status}`);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `Failed to load email reply history. Please try again`,
                });
                return;
            }
    
            const data = await response.json();
            setReplyHistory(data); // Save the API response to state
        } catch (error) {
            console.error("Failed to fetch mail reply history:", error);
             // Show error alert
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Failed to load email reply history. Please try again later.",
            });
        }
    };

    // Function to handle email click
    const handleEmailClick = (internal_id, mail_subject, mail_to_info) => {
        setSelectedInternalID(internal_id);
        setReplyBox(false); // Hide reply box when switching emails
        handleMailReplyHistory(internal_id); // Fetch reply history for the selected email
        setSelectedMail([mail_subject, mail_to_info]);
    };

    // Function to handle reply
    const handleReply = () => {
        if (!replySubject.trim() || !replyContent.trim()) return; // Prevent empty replies

        const newMail = {
            sender_name: "You",
            receiver_time: new Date().toISOString(),
            mail_subject: replySubject,
            mail_content: replyContent,
        };

        // Update the selected company's mail history
        setSelectedCompany((prev) => ({
            ...prev,
            mail_box: [newMail, ...prev.mail_box], // Add new mail to the top
        }));

        // Reset fields and hide reply box
        setReplySubject("");
        setReplyContent("");
        setReplyBox(false);
    };

    // Function to discard reply
    const handleDiscard = () => {
        setReplyBox(false);
        setReplySubject("");
        setReplyContent("");
    };

    const handleCloseRepSuggest = () => {
        setReplyBoxSuggest(false);
    };

    const parseHTML = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, "text/html");
        return doc.body.innerHTML; // Extracts only the body content safely
    };

    const filteredSentMails = sentMails.filter(email => {
        if (activeTab !== 'mail') return true; // Only filter when "Mail" tab is active
        
        const searchLower = searchTerm.toLowerCase();
    
        // Check if the search term matches any name in `to_info`
        const nameMatch = email.to_info?.some(info => 
            info.display_name?.toLowerCase().includes(searchLower)
        );
    
        // If no name match, check if the search term matches any email in `to_info`
        const emailMatch = email.to_info?.some(info => 
            info.identifier?.toLowerCase().includes(searchLower)
        );
    
        return nameMatch || emailMatch;
    });

    const ToggleButton = ({ label, content, type }) => {
        const [isOpen, setIsOpen] = useState(false);
        
        let buttonStyles = "bg-white border-2 text-[#223F9E] px-4 py-2 min-h-[40px] rounded-full shadow-md transition-all text-left border-2 border-[#223F9E] hover:bg-gray-200";
        let contentBoxStyles = "mt-2 p-3 rounded shadow-lg z-30 text-left border-2 border-[#223F9E] bg-white";
        let contentStyles = "text-sm text-left";
    
        if (type === "sentiment") {
            if (isOpen) {
                if (content === "positive") {
                    buttonStyles = "bg-green-500 text-white border-2 px-4 py-2 min-h-[40px] rounded-full shadow-md transition-all text-left";
                } else if (content === "negative") {
                    buttonStyles = "bg-red-500 text-white border-2 px-4 py-2 min-h-[40px] rounded-full shadow-md transition-all text-left";
                } else {
                    buttonStyles = "bg-gray-300 text-black border-2 px-4 py-2 min-h-[40px] rounded-full shadow-md transition-all text-left";
                }
            }
        } else if (type === "emotion") {
            contentBoxStyles += " border border-blue-500 text-black w-full";
            contentStyles += " whitespace-pre-wrap";
        } else if (type === "key_takeaway") {
            contentBoxStyles += " text-black border border-gray-400 w-full";
        }
    
        return (
            <div className="relative inline-block w-auto">
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className={buttonStyles}
                >
                    {isOpen && type === "sentiment" ? (content === "positive" ? "Positive" : "Negative") : label}
                </button>
                
                {isOpen && type !== "sentiment" && (
                    <div className={contentBoxStyles}>
                        <p className={contentStyles}>{content}</p>
                    </div>
                )}
            </div>
        );
    };


    return (
        <div className="flex flex-row gap-4 flex-1 overflow-hidden">
            {/* Left Panel: Email List */}
            <div className="w-1/3 bg-white p-2 space-x-1 rounded-md shadow-sm h-full">
                {/* Top Box with Search and Dropdown */}
                <div className="bg-white mb-2 rounded-md shadow-sm">
                    <div className="flex items-center space-x-1">
                        {/* Search Bar */}
                        <input
                            type="text"
                            placeholder="Search Email or Name..."
                            className="w-full px-4 py-2 text-sm border border-gray-300 rounded-md"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />

                        {/* Dropdown for Sorting Options */}
                        <select className="px-4 py-2 text-sm border border-gray-300 rounded-md">
                            <option value="date">Date</option>
                            <option value="name">Name</option>
                        </select>
                    </div>
                </div>

                {/* Email & LinkedIn List */}
                <div className="bg-gray-100 p-2 rounded-md h-[calc(100vh-230px)] overflow-y-auto">
                    {activeTab === "mail" ? (
                    <div className="flex flex-col h-full">
                        {/* Title */}
                        <h2 className="text-lg font-semibold text-gray-900 mt-2 mb-2 px-2">Inbox Email</h2>

                        {/* Scrollable Email List */}
                        <div className="overflow-y-auto h-full rounded-md p-2">
                        {!filteredSentMails || filteredSentMails.length === 0 ? (
                            <p className="text-center text-gray-500">No Email Sent</p>
                        ) : (
                            <div className="grid grid-cols-1 gap-4">
                            {filteredSentMails.map((mail, index) =>
                                mail.to_info && mail.to_info[0] ? (
                                <div
                                    key={index}
                                    className={`p-4 bg-white rounded-md shadow-sm border cursor-pointer hover:bg-gray-200 ${
                                    selected_internal_id === mail.internal_id ? "bg-gray-300" : ""
                                    }`}
                                    onClick={() => handleEmailClick(mail.internal_id, mail.subject, mail.to_info)}
                                >
                                    <div className="flex justify-between items-center">
                                    <div className="text-lg font-medium text-gray-900">
                                        {mail.to_info[0].display_name}
                                    </div>
                                    <div className="text-sm text-gray-500">
                                        {mail.updated_at ? new Date(mail.updated_at).toLocaleString() : "N/A"}
                                    </div>
                                    </div>
                                </div>
                                ) : (
                                <div key={index} className="p-4 bg-white rounded-md shadow-sm border cursor-pointer">
                                    <p className="text-center text-gray-500">Invalid Email Data</p>
                                </div>
                                )
                            )}
                            </div>
                        )}
                        </div>
                    </div>
                    ) : (
                    // LinkedIn Tab - Under Development
                    <p className="text-center text-gray-500 text-lg font-semibold">Under development</p>
                    )}
                </div>
            </div>

            {/* Right Panel: Mail & LinkedIn History */}
            <div className="w-2/3 flex flex-col h-full">
                {activeTab === "mail" ? (
                    <>
                        {/* Information Box */}
                        <div className="bg-white px-4 py-1 rounded-md shadow-sm flex flex-col">
                            {selectedMail.length > 0 && (
                                <div className="">
                                    <h3 className="text-lg font-semibold">{selectedMail[0] || "Unknown Subject"}</h3>
                                    <p className="text-sm text-gray-600">
                                        To: {selectedMail[1] && Array.isArray(selectedMail[1])
                                            ? selectedMail[1].map((info) => info.identifier).join(", ")
                                            : "Unknown Receiver"}
                                    </p>
                                </div>
                            )}
                        </div>

                        {/* Conversation History */}
                        <div className="flex-1 bg-white p-4 rounded-md shadow-sm overflow-y-auto flex flex-col">
                            {replyHistory && replyHistory.length > 0 ? (
                                <div className="flex-1 space-y-4">
                                    {replyHistory
                                        .slice()
                                        .sort((a, b) => new Date(a.receiver_time) - new Date(b.receiver_time))
                                        .map((mail, idx) => (
                                            <div key={idx} className="bg-gray-100 rounded-md shadow-md overflow-visible relative">
                                                {/* Upper Box - Sender Info */}
                                                <div className="bg-white p-4 shadow border-b">
                                                    <div className="flex justify-between items-center">
                                                        <h2 className="text-lg font-bold">
                                                            {mail.from_info?.display_name || mail.from_info?.identifier || "Unknown Sender"}
                                                        </h2>
                                                        <span className="text-xs text-gray-500">
                                                            {mail.created_at ? new Date(mail.created_at).toLocaleString() : "N/A"}
                                                        </span>
                                                    </div>
                                                </div>

                                                {/* Separator Line */}
                                                <div className="h-[1px] bg-gray-300 w-full"></div>

                                                {/* Lower Box - Email Body */}
                                                <div className="bg-white p-4 shadow-sm">
                                                    <div className="text-gray-700 mt-2 space-y-2">
                                                        {mail.body
                                                            .split("\n\n")
                                                            .map((paragraph, index) => (
                                                                <div key={index} dangerouslySetInnerHTML={{ __html: parseHTML(paragraph) }} />
                                                            ))}
                                                    </div>
                                                </div>

                                                {/* Separator Line Before Information Button */}
                                                <div className="h-[1px] bg-gray-300 w-full"></div>

                                                {/* Information Buttons */}
                                                <div className="bg-white p-4 flex gap-4 items-start text-left">
                                                    <ToggleButton 
                                                        label={<span>Sentiment <span className="text-lg">✨</span></span>} 
                                                        content={mail.sentiment || "N/A"} 
                                                        type="sentiment" 
                                                    />
                                                    
                                                    <ToggleButton 
                                                        label={<span>Emotion <span className="text-lg">✨</span></span>} 
                                                        content={mail.emotions?.join("\n") || "N/A"} 
                                                        type="emotion" 
                                                    />
                                                    
                                                    <ToggleButton 
                                                        label={<span>Key Takeaway <span className="text-lg">✨</span></span>} 
                                                        content={mail.key_takeaways || ""} 
                                                        type="key_takeaway" 
                                                    />
                                                </div>
                                            </div>
                                        ))}

                                    {/* Reply Button Below Last Mail */}
                                    {!replyBox && (
                                        <div className="w-full flex justify-start space-x-2">
                                            <button
                                                className="bg-[#223F9E] text-white px-4 py-2 rounded-full flex items-center gap-2"
                                                onClick={() => setReplyBox(true)}
                                            >
                                                Reply <FaReply />
                                            </button>
                                            <button
                                                className="bg-white border-2 border-[#223F9E] text-[#223F9E] px-4 py-2 rounded-full flex items-center gap-2 shadow-md hover:bg-[#223F9E] hover:text-white transition-all"
                                                onClick={() => setReplyBoxSuggest(true)}
                                            >
                                                Reply Suggestion <span className="text-lg">✨</span>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="flex h-full items-center justify-center">
                                    <h2 className="text-xl font-semibold text-gray-500">Select an email to view the conversation.</h2>
                                </div>
                            )}
                        </div>

                        {/* Reply Box at the Bottom */}
                        {replyBox && (
                            <div className="bg-white p-4 rounded-md shadow border mt-4">
                                <ReactQuill
                                    theme="snow"
                                    value={replyContent}
                                    onChange={setReplyContent}
                                    modules={modules}
                                    className="h-[100px] rounded-full mb-[50px]"
                                    placeholder="Write your reply here..."
                                />
                                <div className="flex justify-start gap-2 mt-2">
                                    <button
                                        className="bg-[#223F9E] w-30 text-white px-4 py-2 rounded-full hover:bg-blue-700"
                                        onClick={handleReply}
                                    >
                                        Reply
                                    </button>
                                    <button
                                        className="bg-gray-300 w-30 text-black px-4 py-2 rounded-full hover:bg-gray-400"
                                        onClick={handleDiscard}
                                    >
                                        Discard
                                    </button>
                                </div>
                            </div>
                        )}

                        {replyBoxSuggest && (
                            <ReplyBox 
                                replyHistory={replyHistory} 
                                handleCloseRepSuggest={() => setReplyBoxSuggest(false)} 
                            />
                        )}
                    </>
                ) : (
                    // LinkedIn Tab - Under Development
                    <div className="flex h-full items-center justify-center">
                        <h2 className="text-xl font-semibold text-gray-500">Under development</h2>
                    </div>
                )}
            </div>
        </div>
    );
};

const Unibox = () => {

    const [activeTab, setActiveTab] = useState('mail');
    const navigate = useNavigate();
    
    const handleBack = () => {
        navigate('/email-status');
    };

    return (
        <div className="h-screen flex flex-col bg-white overflow-hidden">
            <HeaderMainPage />
            {/* Page Content Fullscreen */}
            <div className="flex justify-center items-center flex-grow h-screen">
                {/* Main Box Fullscreen */}
                <div className="bg-white w-full h-full shadow-md rounded-md flex flex-col p-2">
                    {/* Object 1 */}
                    <div className="rounded p-2 flex justify-between items-center">
                        {/* Left Section with Back Button */}
                        <div className="flex items-center space-x-4">
                            <button onClick={handleBack} className="text-gray-600 hover:text-gray-900 rounded-full bg-gray-200 p-2">
                                <FaArrowLeft className="h-6 w-6" />
                            </button>
                            <div>
                                <h1 className="text-lg font-bold">{localStorage.getItem("campaign_name")}</h1>
                                <h2 className="text-md text-gray-600">{localStorage.getItem("core_service")}</h2>
                            </div>
                        </div>

                        {/* Tag Navigation with Equal Space and Bold Text */}
                        <div className="bg-gray-200 rounded-full flex w-[300px] justify-between">
                            <button onClick={() => setActiveTab('mail')} 
                                className={`rounded-full flex-1 px-2 py-2 font-medium text-lg ${activeTab === 'mail' ? 'bg-[#223F9E] text-white' : 'bg-gray-200 text-black'}`}>
                                Mail
                            </button>
                            <button onClick={() => setActiveTab('linkedin')} 
                                className={`rounded-full flex-1 px-2 py-2 font-medium text-lg ${activeTab === 'linkedin' ? 'bg-[#223F9E] text-white' : 'bg-gray-200 text-black'}`}>
                                LinkedIn
                            </button>
                        </div>
                    </div>

                    {/* Object 2 - Using MailboxContent with state */}
                    <MailboxContent activeTab={activeTab} />
                </div>
            </div>
        </div>
    );
}

export default Unibox;