import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { FaPen, FaStar } from 'react-icons/fa';

const CampaignSummary = ({ campaignId, onClose }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [getData, setGetData] = useState(false);
    const roboman_api = process.env.REACT_APP_ROBOMAN_API;

    useEffect(() => {
        const access_token = localStorage.getItem('access_token')

        const fetchCampaignData = async (campaignId, access_token) => {
            try {
                const response = await fetch(`${roboman_api}/campaign/${campaignId}`, {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Authorization": access_token,
                    },
                });

                if (!response.ok) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "The information of this campaign cannot be retrieved. Please try again.",
                        confirmButtonText: "Close",
                    }).then(() => {
                        onClose();
                    });
                    return null;
                }

                const result = await response.json();
                setData({
                    campaignName: result.campaign_name,
                    campaignInfo: {
                      "Core Service": result.campaign_info.core_service,
                      "Unique Selling Proposition (USP)": result.campaign_info.unique_selling_proposition,
                      "Target Audience": result.campaign_info.target_audience,
                      "Problem Solved": result.campaign_info.problem_solved,
                      "Key Benefits": result.campaign_info.key_benefits,
                      "Primary Goal of Outreach Campaign": result.campaign_info.primary_goal_of_outreach_campaign,
                      "Ideal Clients": result.campaign_info.ideal_client,
                      "Success Measurement": result.campaign_info.success_measurement,
                    },
                });
                // console.log("Campaign Data:", data);
                setLoading(false);
                setGetData(true)

                return data;
            } catch (error) {
                console.error("Error fetching campaign:", error);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "The information of this campaign cannot be retrieved. Please try again.",
                    confirmButtonText: "Close",
                }).then(() => {
                    onClose();
                });
                return null;
            }
        };
        
        if (campaignId && getData === false) {
            fetchCampaignData(campaignId, access_token);
        }
    }, [campaignId, getData, onClose]);

    useEffect(() => {
        console.log("Data updated:", data);
    }, [data]);

    if (!campaignId) return null;

    // InfoBox Component
    const InfoBox = ({ title, content}) => {
        return (
            <div className="w-full sm:w-[250px] md:w-[300px] h-[250px] bg-white shadow-md rounded-lg overflow-hidden flex flex-col">
                <div className="bg-white p-3 sm:p-4 flex justify-between items-center">
                    <div className="flex items-center">
                        <div className="w-8 h-8 sm:w-10 sm:h-10 rounded-full flex items-center justify-center mr-2">
                            <FaStar className="text-blue-500 text-xs sm:text-sm" size={30}/>
                        </div>
                            <div className="text-xs sm:text-sm font-bold">{title}</div>
                        </div>
                    </div>
                
                    <div className="flex-grow p-3 sm:p-4 overflow-y-auto border-t">
                        {Array.isArray(content) ? (
                        <ul className="text-gray-700 text-xs sm:text-sm list-disc pl-4 sm:pl-5">
                            {content.map((item, index) => (
                            <li key={index}>{item}</li>
                            ))}
                        </ul>
                        ) : (
                        <p className="text-gray-700 text-xs sm:text-sm">{content}</p>
                        )}
                    </div>
            </div>
        );
    };


    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-4">
            <div className="bg-white p-6 rounded-lg shadow-lg max-h-[90vh] overflow-auto flex flex-col items-center">
                {loading ? (
                    <div className="w-[300px] h-[300px] flex items-center justify-center">
                        <p className="text-lg font-semibold">Loading...</p>
                    </div>
                ) : (
                    <div className="w-full flex flex-col items-center">
                        {/* Title Part */}
                        <h1 className="text-2xl sm:text-3xl font-bold text-center mb-6">
                            {data?.campaignName} Campaign Summary
                        </h1>
    
                        {/* Data Part with Scrolling */}
                        <div className="w-full bg-gray-100 p-5 rounded-lg max-h-[60vh] overflow-y-auto">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 md:gap-4 lg:gap-4 justify-items-center">
                                {Object.entries(data?.campaignInfo || {}).map(([key, value]) => (
                                    <InfoBox key={key} title={key} content={value} />
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                <button 
                    onClick={onClose} 
                    className="mt-6 px-6 py-3 bg-red-500 text-white text-lg font-bold rounded-full transition duration-300 hover:bg-red-700"
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default CampaignSummary;
