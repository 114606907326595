import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../App.css';
import HeaderMainPage from '../../header/HeaderMainPage';
import Swal from 'sweetalert2';
import { fetchCampaignCompanies } from '../managingPage/CompanyDataUtils';
import { FaQuestionCircle, FaEnvelope, FaLinkedin} from 'react-icons/fa';
import ExplainDISC from '../utils/ExplainDISC';
import ExplainOCEAN from '../utils/ExplainOCEAN';
import { PromptBox } from './PromptBox';
import ReactQuill from "react-quill";
import { IoArrowBack } from "react-icons/io5";
import "react-quill/dist/quill.snow.css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import CampaignSummary from "../managingPage/campaignSummary";

const getStatusStyle = (status) => {
  switch (status) {
    case 1: // Not Started
      return { style: 'bg-gray-100 text-gray-600', text: 'Not Started' };
    case 2: // Waiting to Review
      return { style: 'bg-blue-100 text-blue-600', text: 'Waiting to Review' };
    case 3: // Reviewed
      return { style: 'bg-yellow-100 text-yellow-600', text: 'Reviewed' };
    case 4: // Sent
      return { style: 'bg-green-100 text-green-600', text: 'Sent' };
    case 5: // Opened
      return { style: 'bg-yellow-100 text-yellow-600', text: 'Opened' };
    case 6: // Replied
      return { style: 'bg-red-100 text-red-600', text: 'Replied' };
    case 7: // Generating
      return { style: 'bg-gray-100 text-gray-600', text: 'Generating' };
    case 8: // Generate Failed
      return { style: 'bg-green-100 text-green-600', text: 'Generate Failed' };
    case 9: // Sending
      return { style: 'bg-gray-100 text-gray-600', text: 'Sending' };
    case 10: // Send Failed
      return { style: 'bg-green-100 text-green-600', text: 'Send Failed' };
    default: // Other statuses
      return { style: 'bg-transparent text-black', text: 'Unknown' };
  }
};

const modules = {
  toolbar: [
    [{ font: [] }, { size: [] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["blockquote", "code-block"],
    [{ align: [] }],
    ["link", "image"],
    ["clean"],
  ],
};

const EmailStatus = () => {
  // Real data = {"company_email","company_id", "rep_email","content_subject", "email_confirmation_status_id","updated_at","campaign_id","company_name","rep_name","industry","content","created_at"}

  const senderConfig = {
    name: localStorage.getItem("nickname")
  };

  const navigate = useNavigate();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});

  const [showPromptBox, setShowPromptBox] = useState(false);
  const [promptText, setPromptText] = useState('');
  const [promptButtonPosition, setPromptButtonPosition] = useState({ top: 0, left: 0, width: 0, height: 0 });
  const promptButtonRef = useRef(null);
  const promptBoxRef = useRef(null);
  const [campaignState, setCampaignState] = useState(null);
  const isLnkActive = localStorage.getItem('lnk_act') === 'true'; // Check the localStorage value
  let Campaign_Name = localStorage.getItem("campaign_name");

  // Check if both "campaignName" and "selectedCampaign" exist
  // if (localStorage.getItem("selectedCampaign")) {
  //   // If both exist, use "selectedCampaign"
  //   Campaign_Name = localStorage.getItem("selectedCampaign");
  // } 
  
  const roboman_api = process.env.REACT_APP_ROBOMAN_API;
  const [showActionMenu, setShowActionMenu] = useState(false);
  const [showConfigMenu, setShowConfigMenu] = useState(false);
  const ActionMenuRef = useRef(null);
  const ConfigMenuRef = useRef(null);

  // State to store fetched data
  const [localCompaniesData, setLocalCompaniesData] = useState([]);
  const campaign_id = localStorage.getItem("campaign_id")
  const hasRunGenerateSelected = useRef(false);
  const [isDataReady, setIsDataReady] = useState(false)
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ActionMenuRef.current && !ActionMenuRef.current.contains(event.target)) {
        setShowActionMenu(false);
      }
      if (ConfigMenuRef.current && !ConfigMenuRef.current.contains(event.target)) {
        setShowConfigMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     navigate('/my-campaign'); // Redirect to Page A when back is pressed
  //   };

  //   window.addEventListener('popstate', handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener('popstate', handleBeforeUnload);
  //   };
  // }, [navigate]);

  // useEffect(() => {
  //   if (companiesData) {
  //     console.log("data user in storage:", companiesData);
  //     setLocalCompaniesData(companiesData); // Set data from context
  //   } else {
      
  //     console.log("No companies data found, setting empty array.");
  //     setLocalCompaniesData([]); // Fallback to empty array
  //   }
  //   setIsLoading(false); // Data is ready
  // }, [companiesData]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOCEANModalOpen, setIsOCEANModalOpen] = useState(false);

  // Open the DISC modal
  const handleOpenDISCModal = () => {
    setIsModalOpen(true);
  };

  // Close the DISC modal
  const handleCloseDISCModal = () => {
    setIsModalOpen(false);
  };

  // Open the OCEAN modal
  const handleOpenOCEANModal = () => {
    setIsOCEANModalOpen(true);
  };

  // Close the OCEAN modal
  const handleCloseOCEANModal = () => {
    setIsOCEANModalOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const campaignId = localStorage.getItem("campaign_id"); // Replace with actual campaign ID
      const page = 1;  // The page you want to fetch
      const pageSize = 50; // The number of items per page
      const authToken = localStorage.getItem("access_token"); // Your bearer token
      const data = await fetchCampaignCompanies(campaignId, page, pageSize, authToken);
      // console.log(data)
      

      if (data === null) {
        setLocalCompaniesData([]);
      } else {
        setLocalCompaniesData(data)
      }
      setIsDataReady(true);
      
      console.log("Data have save to use...")
  };

  fetchData();
  }, []);

  useEffect(() => {
    // console.log("data user in storage:", companiesData)
    // console.log("data user in local:", localCompaniesData)
    
    const storedCampaignState = localStorage.getItem('campaignState');
    setCampaignState(storedCampaignState);

    if (storedCampaignState === 'Confirm' && isDataReady && localCompaniesData.length > 0 && !hasRunGenerateSelected.current) {
      handleConfirmState();
      hasRunGenerateSelected.current = true;
      }
    // } else if (storedCampaignState === 'Cancel') {
    //   handleCancelState();
    // }
  }, [isDataReady, localCompaniesData]);

  useEffect(() => {
    const updatePromptButtonPosition = () => {
      if (promptButtonRef.current) {
        const rect = promptButtonRef.current.getBoundingClientRect();
        setPromptButtonPosition({
          top: rect.top + window.scrollY,
          left: rect.left + window.scrollX,
          width: rect.width,
          height: rect.height
        });
      }
    };

    const handleClickOutside = (event) => {
      if (showPromptBox && promptBoxRef.current && !promptBoxRef.current.contains(event.target) && event.target !== promptButtonRef.current) {
        setShowPromptBox(false);
      }
    };

    updatePromptButtonPosition();
    window.addEventListener('resize', updatePromptButtonPosition);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('resize', updatePromptButtonPosition);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPromptBox]);

  const [shouldSendMail, setShouldSendMail] = useState(false);

  useEffect(() => {
    if (shouldSendMail) {
      handleSendSelected(true);
      setShouldSendMail(false); // Reset flag to prevent re-triggering
      console.log("Finish");
    }
  }, [localCompaniesData, shouldSendMail]);

  const handleConfirmState = async () => {
    console.log("Start Auto Generate Mail");
    

    console.log("Updated company data:", localCompaniesData); // This still shows state data
    await handleGenerateSelected(true);
    console.log("Start Auto Send Mail");

    // await handleSendSelected(true); // Set flag to trigger sending mails in `useEffect`
    // setShouldSendMail(true);
    console.log("Complete")
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    const newSelectedRows = {};
    localCompaniesData.forEach(row => {
      newSelectedRows[row.company_id] = isChecked;
    });
    setSelectedRows(newSelectedRows);
  };

  const handleRowSelect = (id) => {
    const newSelectedRows = { ...selectedRows, [id]: !selectedRows[id] };
    console.log("Selected Row: ", newSelectedRows)
    setSelectedRows(newSelectedRows);
    setSelectAll(Object.values(newSelectedRows).every(Boolean) && Object.keys(newSelectedRows).length === localCompaniesData.length);
  };

  const handlePromptClick = () => {
    setShowPromptBox(!showPromptBox);
  };

  const handleAutoSend = async () => {
    console.log("Auto Send clicked. Prompt text:", promptText);
    // Here you would typically save the promptText to your state or send it to an API
    // setShowPromptBox(false);
    // Reset the prompt text after sending
    // setPromptText('');

    // Iterate through each row and send emails
    const updatedData = await Promise.all(
        localCompaniesData.map(async (row) => {
        // Check if content is empty before sending
        if (row.content === null) {
          // If content is empty, update the status to 'Failed to Send'
          return { ...row, email_confirmation_status_id: 'Failed to Send' };
        }

        try {
          // Try sending the email
          await sendEmail(row);  // Assuming sendEmail is an async function

          // Update the status to 'Sent' if successful
          return { ...row, email_confirmation_status_id: 2 };
        } catch (error) {
          // If there's an error, update the status to 'Failed to Send'
          return { ...row, email_confirmation_status_id: 'Failed to Send' };
        }
      })
    );

    // Update the state with the new data
    setLocalCompaniesData(updatedData);
  };

  // New function to handle status click
  // const handleStatusClick = (status, id) => {
  //   if (status === 'Replied') {
  //     navigate('/mail-reply', { state: { id } });
  //   }
  // };
  // New functions to handle button clicks
  const handleMailboxClick = () => {
    navigate("/unibox"); // Replace with the actual path to your Mailbox page
  };

  const handleBack = () => {
    localStorage.removeItem("campaign_id");
    navigate('/my-campaign');
  };

  const generateEmail = async(company_id_list, prompt_name) => {
    try {
      const response = await fetch(`${roboman_api}/campaign/emails/generate`, {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Authorization': localStorage.getItem('access_token'),
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              "company_id_list": company_id_list,
              "campaign_id": localStorage.getItem('campaign_id'),
              "prompt_name": prompt_name,
          }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('API Error Details:', errorData);
        console.error('Status Code:', response.status);
        return false
      } else {
        return true
      }

    } catch (error) {
        console.error('Error during email generation:', error);
        return false;
    }
  }

  const generateLinkedInMessage = async(reply_name, linkedInlink) => {

    // receiverName = ""
    const productName = localStorage.getItem('core_service')
    const keyBenefits = localStorage.getItem('key_benefits')
    const problemSolved = localStorage.getItem('problem_solved')
    const sendername = localStorage.getItem('nickname')

    const linkedin_generate_prompt = `
    Write a short linkedin message using the information below
    Name of sender or email writer: ${sendername}
    Name of receiver: ${reply_name}
    Product name: ${productName}
    Key Benefits: ${keyBenefits}
    Problem Solved: ${problemSolved}`;

    // If not found any thing in Must have information and Desired Output Format,
    // write the letter as default, using sender name at the ending.`;

    try {
      const response = await fetch(`${roboman_api}/campaign/email/generation`, {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Authorization': localStorage.getItem('access_token'),
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              "input_str": linkedin_generate_prompt.trim(),
              "rep_linkedin":linkedInlink
          }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('API Error Details:', errorData);
        console.error('Status Code:', response.status);
        return false;   
      } 
      
      const responseData = await response.text(); // Get raw response
      // console.log('Full Response:', responseData);
      const responseText = JSON.parse(responseData);
      // console.log('Text Response:', responseText.generated_email);
      const responseText1 = responseText.generated_email
      console.log('Text Response:', responseText1);
      
      let parsedData;
      try {
        parsedData = JSON.parse(responseText1); // Parse the raw string to JSON
        localStorage.setItem('parsedData', responseText1)
        return true
      } catch (parseError) {
        console.error('Error parsing response JSON:', parseError);
        return false; // Return null values if parsing fails
      }

    } catch (error) {
        console.error('Error during linkedin message generation:', error);
        return false;
    }
  };

  const handleGenerate = async (company_id) => {
    const loadingSwal = Swal.fire({
      title: 'Generating Email',
      text: 'Please wait while the email content is being generated.',
      icon: 'info',
      allowOutsideClick: false, // Prevent closing the alert by clicking outside
      didOpen: () => {
        Swal.showLoading(); // Show loading spinner
      },
    });

    if (campaignState !== "Cancel") return;
    console.log("start generating")
    // Update status to "Generating..."
    setLocalCompaniesData((prevData) =>
      prevData.map((row) =>
        row.company_id === company_id ? { ...row, email_confirmation_status_id: 7, content: "Generating..." } : row
      )
    );
    //Generate Email
    const company_id_list = [company_id]
    let prompt_type = "email_generation_v1" 
    if (selectedPrompt != null) {
      prompt_type = selectedPrompt.prompt_name
    }
    // setSelectedPrompt(null)
    const genresult = await generateEmail(company_id_list, prompt_type);
    
    
    // Wait for 5 seconds before updating data
    await new Promise((resolve) => setTimeout(resolve, 8000));
     // Ensure updateData completes
    
    // Close the loading Swal
    Swal.close();


    // Dismiss the loading alert and show success message
    if (genresult===true){
      Swal.fire({
        title: 'Success!',
        text: 'Email content has been successfully generated and updated.',
        icon: 'success',
        confirmButtonText: 'OK'
      }).then(() => {
        updateData(); // Call your function
      });
    } else {

      // Dismiss the loading alert and show error message
      Swal.fire({
        title: 'Error!',
        text: 'Failed to generate email content. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK'
      }).then(() => {
        updateData(); // Call your function
      });
    }

    // await updateData();
  };

  const sendEmail = async (comp_id_list) => {
    
    try {
      // Determine the recipient email based on the provided logic
      const response = await fetch(`${roboman_api}/campaign/emails/send`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': localStorage.getItem('access_token'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          company_id_list: comp_id_list,
        })
      });

      if (!response.ok) {
        const errorData = await response.text(); // Capture raw text for error handling
        console.error('API Error:', errorData);
        return;
      } else {
        console.log('Email sending successful');
      }

      // return await response.json();
    } catch (error) {
      console.log('Email sending error:', error);
    }
  };

  const handleSend = async (company_id) => {
    // if (campaignState === 'Cancel') {
      const rowData = localCompaniesData.find((row) => row.company_id === company_id);
  
      if (!rowData.content.trim() || rowData.content === null || !rowData.content_subject.trim() || rowData.content_subject === null) {
        Swal.fire({
          icon: 'error',
          title: 'Empty Content',
          text: 'Please add content and subject before sending the email',
          confirmButtonColor: '#3085d6',
        });
        return;
      }
  
      try {
        // Show loading alert
        Swal.fire({
          title: 'Sending...',
          text: 'Please wait while the email is being sent.',
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
  
        // Sending email
        sendEmail([company_id]);
  
        //
        await updateEmailStatus(company_id, rowData.content_subject, rowData.content, 4);
  
        // Update status to 'Sent' on success
        const updatedData = localCompaniesData.map((row) =>
          row.company_id === company_id ? { ...row, email_confirmation_status_id: 4 } : row
        );
  
      } catch (error) {
        //
        await updateEmailStatus(company_id, rowData.content_subject, rowData.content, 10);
  
        // Update status to 'Failed to Send' on error
        const updatedData = localCompaniesData.map((row) =>
          row.company_id === company_id ? { ...row, email_confirmation_status_id: 10 } : row
        );
      }
  
      // Wait for 5 seconds before updating data
      await new Promise((resolve) => setTimeout(resolve, 5000));
  
      // Close the loading alert and show success message
      Swal.close();
      await updateData();
    // }
  };  

  const updateData = async () => {
      const page = 1;  // The page you want to fetch
      const pageSize = 50; // The number of items per page
      const authToken = localStorage.getItem("access_token"); // Your bearer token
      const data = await fetchCampaignCompanies(campaign_id, page, pageSize, authToken);

      // setLocalCompaniesData(data);

      if (data === null) {
        setLocalCompaniesData([]);
      } else {
        setLocalCompaniesData(data);
      }
      setIsDataReady(true);
      console.log("Data have updated...")
  };

  // Review email
  const [showPopup, setShowPopup] = useState(false);
  const [editingSubject, setEditingSubject] = useState('');
  const [editingContent, setEditingContent] = useState('');
  const [editingId, setEditingId] = useState(null);
  const [emailData, setEmailData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleReview = async(company_id) => {
    try {
      const response = await fetch(`${roboman_api}/campaign/emails/draft/${company_id}`, {
        method: "GET",
        headers: {
          "accept": "application/json",
          "Authorization": localStorage.getItem('access_token')
        }
      });
      
      if (!response.ok) {
        console.error("Failed to fetch email drafts:", response.status, response.statusText);
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: `Failed to fetch email drafts: ${response.status} ${response.statusText}`,
        });
        return;
      }

      console.log("retrieve mail successfull")
      const data = await response.json();
      // Sort data by draft_type in ascending order
      const sortedData = [...data].sort((a, b) => a.draft_type - b.draft_type);

      setEmailData(sortedData);
      setShowPopup(true);
    } catch (error) {
      console.error("Error fetching emails:", error);
      Swal.fire({
        icon: "error",
        title: "Network Error",
        text: "Something went wrong while fetching emails. Please try again later.",
      });
    }
  };

  const handleAcceptChange = async() => {

    setShowPopup(false);
    await updateEmailStatus(campaign_id, editingId, editingSubject, editingContent,4)
    await updateData();
    
    console.log("mail has been changed")
    
  };

  const handleCancel = async() => {
    setShowPopup(false);
    const mainMail = emailData[0];

    await updateEmailStatus(mainMail.company_id, mainMail.subject,mainMail.body, 3)
    await updateData();
    
    // setEditingContent('');
    // setEditingId(null);
    setEmailData([])
    setCurrentIndex(0)
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? 3 : prevIndex - 1));
  };
  
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 3 ? 0 : prevIndex + 1));
  };
  
  let currentEmail = emailData[currentIndex];

  const getTitle = () => {
    // console.log("current mail index:", currentIndex)
    // console.log("current mail", currentEmail)
    const titles = {
      0: "View Email",
      3: "View Final Follow-up Email",
    };
  
    return titles[currentEmail.draft_type] || "View Follow-up Email";
  };

  const handleChangeSubject = (subject) => {
    // console.log("Before Update:", emailData);
    setEmailData((prev) => {
      const updatedEmailsSubject = prev.map((email, index) =>
        index === currentIndex ? { ...email, subject } : email
      );
      // console.log("Updated Emails:", updatedEmails); // Check new state
      return updatedEmailsSubject;
    });
  };

  const handleChangeBody = (body) => {
    // console.log("Before Update:", emailData);
    setEmailData((prev) => {
      const updatedEmailsBody = prev.map((email, index) =>
        index === currentIndex ? { ...email, body } : email
      );
      // console.log("Updated Emails:", updatedEmailsBody); // Check new state
      return updatedEmailsBody;
    });
  };

  const handleUpdateMail = async () => {
    try {
      const response = await fetch(`${roboman_api}/campaign/emails/draft/update`, {
        method: 'PUT',
        headers: {
          'accept': 'application/json',
          'Authorization': localStorage.getItem('access_token'), // Replace with actual token
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          company_id: currentEmail.company_id,
          draft_id: currentEmail.draft_id,
          subject: currentEmail.subject,
          body: currentEmail.body,
        }),
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        console.error('Error updating email draft:', data);
        Swal.fire({
          title: 'Error!',
          text: data.message || 'Failed to update email draft.',
          icon: 'error',
        });
        return;
      }
  
      Swal.fire({
        title: 'Success!',
        text: 'Email draft updated successfully.',
        icon: 'success',
      });
    } catch (error) {
      console.error('Network or server error:', error);
      Swal.fire({
        title: 'Error!',
        text: 'An unexpected error occurred. Please try again.',
        icon: 'error',
      });
    }
  };

  // Contact code
  const [isPopUpAddContact, setPopUpAddContact] = useState(false);
  const [newContact, setNewContact] = useState({
    company_name: '',
    industry: '',
    company_email: '',
    company_linkedin: '',
    rep_name: '',
    rep_email: '',
    rep_linked: '',
  });

  const handleAddContactClick = () => {
    setPopUpAddContact(true);
    setShowConfigMenu(false);
  };

  const handleAddContact = async () => {
     // Check if required fields are filled
    const { company_name, industry, company_email, rep_email } = newContact;
    if (!company_name || !industry || !company_email || !rep_email) {
      Swal.fire({
        icon: 'warning',
        title: 'Missing Information',
        text: 'Please fill in all the required fields: Company Name, Industry, Company Email, and Reply Email.',
        confirmButtonText: 'OK',
      });
      return; // Stop the function execution if validation fails
    }

    try {
      // Prepare the contact data
      const contactData = {
        company_name: newContact.company_name,
        industry: newContact.industry,
        company_email: newContact.company_email,
        company_linkedin: newContact.company_linkedin,
        rep_name: newContact.rep_name,
        rep_email: newContact.rep_email,
        rep_linkedin: newContact.rep_linked,
      };

      console.log("new contact data", contactData)
  
      // Send the data to your API (replace with actual API endpoint)
      const response = await fetch(`${roboman_api}/campaign/${localStorage.getItem("campaign_id")}/companies/add`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Authorization': localStorage.getItem('access_token'), // Use your actual token here
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(contactData),
      });
  
      if (!response.ok) {
        console.error('Failed to add contact:', response);
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'There was a problem adding the contact. Please try again later.',
          confirmButtonText: 'OK',
        });
        return;
      }
  
      // Optionally, get the response data if needed
      const data = await response.json();
  
      // Reset the form state
      setNewContact({
        company_name: '',
        industry: '',
        company_email: '',
        company_linkedin: '',
        rep_name: '',
        rep_email: '',
        rep_linked: '',
      });
  
      
  
      // Show success alert
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'The contact has been successfully added.',
        confirmButtonText: 'OK',
      });
    
    
    } catch (error) {
      console.error('Error adding contact:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'There was a problem adding the contact. Please try again later.',
        confirmButtonText: 'OK',
      });
    }
    await updateData();
  };
  

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setNewContact((prev) => ({ ...prev, [name]: value }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const newContactWithId = { ...newContact, id: Date.now() }; // Add a unique ID
    setLocalCompaniesData((prevData) => [newContactWithId, ...prevData]); // Add to the top of the list
    setNewContact({ company_name: '', industry: '', company_email: '', company_linkedin: '', rep_name: '', rep_email:'', rep_linked:''}); // Reset form
    setPopUpAddContact(false); // Close the pop-up
  };

  const handlePopUpClose = () => {
    setPopUpAddContact(false);
  };

  useEffect(() => {
    console.log("Updated selectedRows:", selectedRows);
  }, [selectedRows])

  // handle generate - send
  const handleGenerateSelected = async (all = false) => {
    if (showActionMenu === true) {toggleActionMenu()}
    // Delay function defined inside handleGenerateSelected
    // const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    // Select rows to generate based on `all` parameter
    const selectedIds = all ? localCompaniesData.map((row) => row.company_id) : Object.keys(selectedRows).filter((company_id) => selectedRows[company_id]);

    if (selectedIds.length === 0) {
      console.log("No rows selected.");
      Swal.fire({
        title: 'No Emails Selected',
        text: 'Please select at least one row to generate an email.',
        icon: 'warning',
      });
      setSelectedRows({});
      setSelectAll(false);
      return;
    }

    console.log(selectedIds)

    // Filter out rows that are already "Waiting to Review"
    const rowsToGenerate = selectedIds.filter(
      (company_id) => {
        const row = localCompaniesData.find((row) => row.company_id === company_id);
        // console.log(`Checking row with ID ${id}:`, row);
        return row && ![2,4,5,6,7,9,10].includes(row.email_confirmation_status_id); // Only process rows not in "Waiting to Review" or "Send" status
      }
    );

    console.log("check row", rowsToGenerate)

    if (rowsToGenerate.length === 0) {
      console.log("All selected rows are already in 'Waiting to Review' status.");
      Swal.fire({
        title: 'No Emails Ready',
        text: 'Please check again the status of the mail',
        icon: 'warning',
      });
      setSelectedRows({});
      setSelectAll(false);
      return;
    }

    Swal.fire({
      title: 'Generating Email',
      text: 'Please wait while the email content is being generated.',
      icon: 'info',
      allowOutsideClick: false, // Prevent closing the alert by clicking outside
      didOpen: () => {
        Swal.showLoading(); // Show loading spinner
      },
    });

    console.log("start generating")
    setLocalCompaniesData((prevData) =>
      prevData.map((row) =>
        rowsToGenerate.includes(row.company_id)
          ? { ...row, email_confirmation_status_id: 7, content: "Generating..." }
          : row
      )
    );
    // Process in API
    console.log("Selected Prompt:", selectedPrompt)
    let prompt_type = "email_generation_v1" 
    if (selectedPrompt != null) {
      prompt_type = selectedPrompt.prompt_name
    }
    const genresult = await generateEmail(rowsToGenerate, prompt_type);
    const time = 8000*rowsToGenerate.length
    await new Promise((resolve) => setTimeout(resolve, time));

    // Close the loading Swal
    Swal.close();

    if (genresult===true){
      Swal.fire({
        title: 'Success!',
        text: 'Email content has been successfully generated and updated.',
        icon: 'success',
      });
    } else {

      // Dismiss the loading alert and show error message
      Swal.fire({
        title: 'Error!',
        text: 'Failed to generate email content. Please try again.',
        icon: 'error',
      });
    }
    await updateData();
    setSelectedRows({});
    setSelectAll(false);
    console.log("selectedrows after generated", selectedRows)
    return;
  };

  const handleSendSelected = async (all = false) => {
    if (showActionMenu === true) {toggleActionMenu()}

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  
    const selectedIds = all
      ? localCompaniesData.map((row) => row.company_id)
      : Object.keys(selectedRows).filter((company_id) => selectedRows[company_id]);
  
    if (selectedIds.length === 0) {
      console.log("No rows selected.");
      return;
    }
  
    const rowsToSend = selectedIds.filter((company_id) => {
      const row = localCompaniesData.find((row) => row.company_id === company_id);
      // console.log(row)
      return row && 
        ([2,3,4,5,6,10].includes(row.email_confirmation_status_id))  
        // && (row.content && row.content.trim() !== "" && row.content !== null) && 
        // (row.content_subject && row.content_subject.trim() !== "" && row.content_subject !== null);
    });

    console.log("Row to send:", rowsToSend)
  
    if (rowsToSend.length === 0) {
      Swal.fire({
        icon: "error",
        title: "No Emails Ready",
        text: "Please check again the mail. Mails maybe are not created or sent",
        confirmButtonColor: "#3085d6",
      });
      return;
    }

    // const batchSize = 3;
    // console.log(`Processing ${rowsToSend.length} rows in batches of ${batchSize}...`);
  
    // const updatedLocalComData = [...localCompaniesData]; // Create a new copy of the data
    // let successCount = 0;
    // let failureCount = 0;

    Swal.fire({
      title: "Loading...",
      text: "Please wait while sendind mail.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    // for (let i = 0; i < rowsToSend.length; i += batchSize) {
    //   const batch = rowsToSend.slice(i, i + batchSize);
  
    //   await Promise.all(
    //     batch.map(async (company_id) => {
    //       const index = updatedLocalComData.findIndex((row) => row.company_id === company_id);
    //       if (index === -1) return;
  
    //       const rowData = updatedLocalComData[index];
  
    //       if (!rowData.content.trim()) {
    //         updatedLocalComData[index] = { ...rowData, email_confirmation_status_id: 1 };
    //         failureCount++;
    //         return;
    //       }
  
    //       try {
    //         await sendEmail(rowData); // Replace with your actual email function
    //         // updatedLocalComData[index] = { ...rowData, email_confirmation_status_id: 2 };
    //         await updateEmailStatus(campaign_id, company_id, rowData.content_subject, rowData.content, 3)
    //         successCount++;
    //       } catch (error) {
    //         console.error(`Error sending email for row ${company_id}:`, error);
    //         // updatedLocalComData[index] = { ...rowData, email_confirmation_status_id: 7 };
    //         await updateEmailStatus(campaign_id, company_id, rowData.content_subject, rowData.content, 7)
    //         failureCount++;
    //       }
    //     })
    //   );
  
    //   await delay(2000);
    // }

    try {
      await sendEmail(rowsToSend); // Replace with your actual email function
      // await updateEmailStatus(campaign_id, company_id, rowData.content_subject, rowData.content, 3)
    } catch (error) {
      console.error("Error sending email", error);
      // await updateEmailStatus(campaign_id, company_id, rowData.content_subject, rowData.content, 7)
    }

    Swal.close();
    await updateData();
    // setLocalCompaniesData(updatedLocalComData); // Update state once after processing
    setSelectedRows({});
    setSelectAll(false);
    return;

    // Final summary message
    // if (failureCount === 0) {
    //   Swal.fire({
    //     icon: "success",
    //     title: "All Emails Sent Successfully",
    //     text: `Successfully sent ${successCount} emails.`,
    //     confirmButtonColor: "#3085d6",
    //   });
    // } else {
    //   Swal.fire({
    //     icon: "warning",
    //     title: "Some Emails Failed to Send",
    //     text: `Successfully sent ${successCount} emails. ${failureCount} emails failed. Please review and try again.`,
    //     confirmButtonColor: "#3085d6",
    //   });
    // }
  };

  const handleDeleteSelected = async () => {
    if (showActionMenu === true) { 
      toggleActionMenu(); 
    }
  
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  
    // Get selected row IDs from the selectedRows object
    const selectedIds = Object.keys(selectedRows).filter((company_id) => selectedRows[company_id]);
  
    if (selectedIds.length === 0) {
      console.log("No rows selected.");
      Swal.fire({
        icon: "error",
        title: "No Rows Selected for Deletion",
        text: "Please select rows to delete.",
        confirmButtonColor: "#3085d6",
      });
      return;
    }
  
    const rowsToDelete = selectedIds.filter((company_id) => {
      const row = localCompaniesData.find((row) => row.company_id === company_id);
      return row; // Only keep valid rows
    });
  
    console.log("Rows to delete:", rowsToDelete);
  
    if (rowsToDelete.length === 0) {
      Swal.fire({
        icon: "error",
        title: "No Rows Found for Deletion",
        text: "There were no rows found to delete. Please select valid rows.",
        confirmButtonColor: "#3085d6",
      });
      return;
    }
  
    const batchSize = 3;
    console.log(`Processing ${rowsToDelete.length} rows in batches of ${batchSize}...`);
  
    let successCount = 0;
    let failureCount = 0;
  
    Swal.fire({
      title: "Deleting...",
      text: "Please wait while deleting records.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  
    // Deleting in batches
    for (let i = 0; i < rowsToDelete.length; i += batchSize) {
      const batch = rowsToDelete.slice(i, i + batchSize);
  
      await Promise.all(
        batch.map(async (company_id) => {
          try {
            const response = await fetch(`${roboman_api}/campaign/companies/${company_id}/delete`, {
              method: 'DELETE',
              headers: {
                'accept': 'application/json',
                'Authorization': localStorage.getItem('access_token'),
              },
            });
  
            if (!response.ok) {
              throw new Error(`Failed to delete company_id: ${company_id}`);
            }
  
            console.log(`Successfully deleted company_id: ${company_id}`);
            successCount++;
          } catch (error) {
            console.error(`Error deleting company_id ${company_id}:`, error);
            failureCount++;
          }
        })
      );
  
      await delay(2000); // Delay between batch requests
    }
  
    Swal.close();
    await updateData(); // Refresh the data after deletion
    setSelectedRows({}); // Reset selected rows
    setSelectAll(false); // Reset select all
  
    // Final summary message
    if (failureCount === 0) {
      Swal.fire({
        icon: "success",
        title: "All Records Deleted Successfully",
        text: `Successfully deleted ${successCount} records.`,
        confirmButtonColor: "#3085d6",
      });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Some Records Failed to Delete",
        text: `Successfully deleted ${successCount} records. ${failureCount} deletions failed. Please review and try again.`,
        confirmButtonColor: "#3085d6",
      });
    }
  };

  //send linkedin will rework later
  const handleSendLinked = async (company_id) => {
    console.log("send linkedin message")
    if (campaignState === 'Cancel') {
      const rowData = localCompaniesData.find((row) => row.company_id === company_id);
  
      if (!rowData.linkedin_message.trim() || rowData.linkedin_message === null || !rowData.content_subject.trim() || rowData.content_subject === null) {
        Swal.fire({
          icon: 'error',
          title: 'Empty Messsage',
          text: 'Please add content before sending the message',
          confirmButtonColor: '#3085d6',
        });
        return;
      }  
      if (!rowData.company_contact.rep_linkedin.trim() || rowData.company_contact.rep_linkedin === null) {
        Swal.fire({
          icon: 'error',
          title: 'Empty Linkedin',
          text: 'This company have no linkedin reply information',
          confirmButtonColor: '#3085d6',
        });
        return;
      }

      const requestBody = {
        recipient_address: rowData.company_contact.rep_linkedin,
        subject: rowData.content_subject,
        content: rowData.content,
      };
      

      try {
        const response = await fetch(`${roboman_api}/campaign/send-linked-message`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': localStorage.getItem('access_token'),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });
  
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
  
        const result = await response.json();
        await Swal('Success', 'Message sent successfully!', 'success');
        console.log(result);

      } catch (error) {
        await Swal('Error', error.message, 'error');
        
      }
      await updateData()
    }
  };

  const handleAutoPilot = async () => {
    // Select all company IDs
    const selectedIds = localCompaniesData.map((row) => row.company_id);

    if (selectedIds.length === 0) {
      await Swal.fire({
        title: "No Companies Selected",
        text: "There are no company IDs available for AutoPilot. Please check your data and try again.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Swal.fire({
    //   title: "Processing AutoPilot",
    //   text: "Please wait while we process your request...",
    //   icon: "info",
    //   allowOutsideClick: false,
    //   showConfirmButton: false,
    //   didOpen: () => {
    //     Swal.showLoading();
    //   },
    // });

    try {
      const response = await fetch(`${roboman_api}/campaign/emails/outreach/auto`,{
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Authorization': localStorage.getItem('access_token'),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            campaign_id: campaign_id,
            company_id_list: selectedIds,
            prompt_name: "email_generation_v1"
          }),
        }
      );

      if (!response.ok) {
        console.error("Failed to process AutoPilot.");
        await Swal.fire({
          title: "AutoPilot Failed",
          text: "We encountered an issue while processing AutoPilot. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }

      await Swal.fire({
        title: "AutoPilot Started",
        text: "AutoPilot is now processing your request. Please refresh the page in a few minutes to check the status.",
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.error("AutoPilot Error:", error);
      await Swal.fire({
        title: "AutoPilot Failed",
        text: "We encountered an issue while processing AutoPilot. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
   
    await updateData();
    setSelectedRows({});
    setSelectAll(false);
    return;
  }
  

  //
  const [generatePromptAction, setGeneratePromptAction] = useState(false)
  const toggleActionMenu = () => {
    setShowActionMenu(!showActionMenu);
    setGeneratePromptAction(true);
  };

  const toggleConfigMenu = () => {
    setShowConfigMenu(!showConfigMenu);
    setGeneratePromptAction(true);
  };

   // Conditional Rendering: Show loading or data
  // if (localCompaniesData===null) {
  //   return <div>Loading...</div>; // Show loading message or spinner
  // }  

  const updateEmailStatus = async (companyId, contentSubject, content, emailConfirmationStatusId) => {
    try {
      const response = await fetch(`${roboman_api}/campaign/companies/update-email-status`, {
        method: 'PUT',
        headers: {
          'accept': 'application/json',
          'Authorization': localStorage.getItem("access_token"),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          company_id: companyId,
          content_subject: contentSubject,
          content: content,
          email_confirmation_status_id: emailConfirmationStatusId
        })
      });
   
      if (!response.ok) {
        console.error(`HTTP error! status: ${response.status}`);
        return null;
      }
   
      const data = await response.json();
      console.log("Update Successfully")
      return data;
      
    } catch (error) {
      console.error('Error updating email status:', error);
      return null;
    }
  }

  const updateLinkedinMessage = async (campaignId, companyId, linkedin_message) => {
    try {
      const response = await fetch(`${roboman_api}/campaign/companies/update-linkedin-msg-status`, {
        method: 'PUT',
        headers: {
          'accept': 'application/json',
          'Authorization': localStorage.getItem("access_token"),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          company_id: companyId,
          linkedin_message: linkedin_message,
        })
      });
   
      if (!response.ok) {
        console.error(`Cannot update linkedin messsage:`);
        return null;
      }
   
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error updating linkedin messsage:', error);
      return null;
    }

  }

  const IconWithPopup = ({ message }) => {
    const [isHovered, setIsHovered] = useState(false);
  
    return (
      <div 
        className="relative inline-block"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="text-lg cursor-pointer ml-2 mb-4">
          <FaQuestionCircle /> {/* Replace 🔔 with FaQuestionCircle */}
        </div>
        {isHovered && (
          <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-[300px] p-2 bg-gray-800 text-white text-sm rounded shadow-lg z-10">
            {message}
          </div>
        )}
      </div>
    );
  };

  const handleUpdateCampaign = () => {
    navigate("/update-campaign")

  }

  const handleProfileClick = (company_id) => {
    const linkedInLink = localCompaniesData.find((row) => row.company_id === company_id)?.rep_linkedin_address;
    // console.log("LinkedInlink", linkedInLink)
    localStorage.setItem("LinkedInlink", linkedInLink)
    localStorage.setItem("company_id", company_id)
    navigate("/viewinsight")
  }

  const capitalizeWords = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // function for Prompt Box UI

  const [isPromptBoxOpen, setPromptBoxOpen] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  // const [] = useState('em')
  const [promptData, setPromptData] = useState([]);
  
  const togglePromptPopup = async () => {
    setShowConfigMenu(false);
    setPromptBoxOpen(!isPromptBoxOpen);
    if (!isPromptBoxOpen) {
      try {
        const response = await fetch(`${roboman_api}/nlp/prompts/email-gen`, {
          method: 'GET',
          headers: {
            'accept': 'application/json',
            'Authorization': localStorage.getItem('access_token')
          }
        });
        const result = await response.json();
        // console.log(result);
        setPromptData(result || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };
  
  const handlePromptSelect = (prompt) => setSelectedPrompt(prompt);
  const handlePromptCancel = () => {
    console.log("User canceled selection");
    setSelectedPrompt(null);
    setPromptBoxOpen(false);
    setGeneratePromptAction(false);
  };
  const handlePromptConfirm = () => {
    // console.log("User selected prompt:", selectedPrompt);
    setPromptBoxOpen(false);
  };

  const handleGenerateSelectedPrompt = () => {
    if (showActionMenu === true) {toggleActionMenu()}
    togglePromptPopup()
  }
  const handleGenerateWithPrompt = async () => {
    setPromptBoxOpen(false);
    await handleGenerateSelected(false);
  }

  const handleViewSummary = async () => {
    setSelectedCampaign(localStorage.getItem('campaign_id'))
  }

  return (
    <div className="bg-white min-h-screen flex flex-col items-center">
      <HeaderMainPage />

      <div className="w-full max-w-[1700px] mx-auto mt-10 bg-white rounded-lg shadow-lg overflow">
        <div className="flex justify-between items-center p-6 bg-gray-50">
          <div className="flex items-center gap-4">
            <button 
              onClick={handleBack} 
              className="p-2 text-black bg-gray-200 rounded-full hover:bg-gray-300"
              aria-label="Back"
            >
              <IoArrowBack size={20} />
            </button>
            <h2 className="text-2xl font-bold text-black">{`${Campaign_Name}: Prospect List`}</h2>
          </div>
          <div className="flex flex-wrap items-center gap-4">
            <button onClick={handleViewSummary} className="px-4 py-2 bg-white text-gray-700 rounded-full border border-gray-300 hover:border-2 hover:border-gray-500 hover:bg-gray-100 transition-all duration-200">Campaign Summary</button>
            {/* <button onClick={handleUpdateCampaign} className="px-4 py-2 bg-white text-gray-700 rounded-full border border-gray-300 hover:border-2 hover:border-gray-500 hover:bg-gray-100 transition-all duration-200">Update Campaign</button> */}
            {/* <button onClick={handleAddContactClick} className="px-4 py-2 bg-white text-gray-700 rounded-full border border-gray-300 hover:border-2 hover:border-gray-500 hover:bg-gray-100 transition-all duration-200">Add Contact</button> */}
            <button onClick={handleMailboxClick} className="px-4 py-2 bg-white text-gray-700 rounded-full border border-gray-300 hover:border-2 hover:border-gray-500 hover:bg-gray-100 transition-all duration-200">Unibox</button>
            {/* <button onClick={handleCampaignClick} className="px-4 py-2 bg-white text-gray-700 rounded-full hover:bg-gray-100 transition-colors duration-200">Campaigns</button> */}
            {/* <button onClick={handleDeleteClick} className="px-4 py-2 bg-white text-gray-700 rounded-full hover:bg-gray-100 transition-colors duration-200">Delete</button> */}
            
            {/* <button onClick={togglePromptPopup} className="px-4 py-2 bg-white text-gray-700 rounded-full border border-gray-300 hover:border-2 hover:border-gray-500 hover:bg-gray-100 transition-all duration-200">Prompt Config</button> */}

            <div className="relative" ref={ConfigMenuRef}>
              <button onClick={toggleConfigMenu} className="px-4 py-2 bg-white text-gray-700 rounded-full border border-gray-300 hover:border-2 hover:border-gray-500 hover:bg-gray-100 transition-all duration-200">
                Config <span className="ml-1">▼</span>
              </button>
              {showConfigMenu && (
                <div className="absolute top-full left-0 bg-white border border-gray-200 rounded-md shadow-lg z-50 mt-1">
                  <button onClick={handleUpdateCampaign} className="block w-[200px] h-[50px] py-2 px-4 text-left hover:bg-gray-100 text-gray-700 rounded-t-md font-semibold">Update Campaign</button>
                  <button onClick={handleAddContactClick} className="block w-[200px] h-[50px] py-2 px-4 text-left hover:bg-gray-100 text-gray-700 rounded-b-md font-semibold">Add Contact</button>
                  <button onClick={togglePromptPopup} className="block w-[200px] h-[50px] py-2 px-4 text-left hover:bg-gray-100 text-gray-700 rounded-b-md font-semibold">Prompt Config</button>
                </div>
              )}
            </div>

            <div className="relative" ref={ActionMenuRef}>
              <button onClick={toggleActionMenu} className="px-4 py-2 bg-white text-gray-700 rounded-full border border-gray-300 hover:border-2 hover:border-gray-500 hover:bg-gray-100 transition-all duration-200">
                Action <span className="ml-1">▼</span>
              </button>
              {showActionMenu && (
                <div className="absolute top-full left-0 bg-white border border-gray-200 rounded-md shadow-lg z-50 mt-1">
                  <button onClick={() => handleGenerateSelected(false)} className="block w-[200px] h-[50px] py-2 px-4 text-left hover:bg-gray-100 text-gray-700 rounded-t-md font-semibold">Generate</button>
                  <button onClick={handleGenerateSelectedPrompt} className="block w-[200px] h-[50px] py-2 px-4 text-left hover:bg-gray-100 text-gray-700 rounded-b-md font-semibold">Generate with prompt</button>
                  <button onClick={() => handleSendSelected(false)} className="block w-[200px] h-[50px] py-2 px-4 text-left hover:bg-gray-100 text-gray-700 rounded-b-md font-semibold">Send</button>
                  <button onClick={handleDeleteSelected} className="block w-[200px] h-[50px] py-2 px-4 text-left hover:bg-gray-100 text-gray-700 rounded-b-md font-semibold">Delete</button>
                </div>
              )}
            </div>
            
            {/* <button 
              ref={promptButtonRef}
              className="px-4 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors duration-200" 
              onClick={handlePromptClick}
            >
              Prompt
            </button> */}
            <button 
              ref={promptButtonRef}
              className="px-4 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors duration-200" 
              onClick={handleAutoPilot}
            >
              AutoPilot
            </button>
          </div>
        </div>
        
        <div className="overflow-x-auto">
          <div className="max-h-[calc(100vh-300px)] overflow-y-auto">
            <table className="w-full">
              <thead className="bg-gray-50 sticky top-0">
                <tr>
                  <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                  </th>
                  <th className="w-32 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Company</th>
                  <th className="w-32 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Industry</th>
                  <th className="w-32 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Representative</th>
                  <th className="w-36 px-3 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={handleOpenDISCModal}>DISC Profile <FaQuestionCircle  className="inline-block" size={12}/> </th>
                  <th className="w-36 px-3 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={handleOpenOCEANModal}>OCEAN Profile <FaQuestionCircle  className="inline-block" size={12}/> </th>
                  <th className="w-90 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Content</th>
                  <th className="w-48 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Progress</th>
                  <th className="w-48 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                  <th className="w-48 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {localCompaniesData.map(row => (
                  <tr key={row.company_id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <input
                        type="checkbox"
                        checked={selectedRows[row.company_id] || false}
                        onChange={() => handleRowSelect(row.company_id)}
                        className="form-checkbox h-5 w-5 text-blue-600"
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900 truncate max-w-[128px]">
                        {capitalizeWords(row.company_name)}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500 truncate max-w-[128px]">
                        {capitalizeWords(row.industry)}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {/* <div className="text-sm text-gray-500 truncate max-w-[256px]" title={row.company_contact?.company_email || row.company_contact?.rep_email || ""}>
                        {row.company_contact?.company_email || row.company_contact?.rep_email || ""}
                      </div> */}
                      <div className="text-sm text-gray-500 truncate max-w-[128px]">
                        {capitalizeWords(row.rep_name) || ""}
                      </div>
                    </td>
                    {/* DISC and OCEAN profile */}
                    <td className="px-3 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500 truncate w-32" onClick={() => handleProfileClick(row.company_id)}>
                        {row.disc_label && row.disc_label.length > 0
                          ? row.disc_label.map((item, index) => (
                              <span key={`disc-${index}`} className="inline-block px-2 py-1 bg-gray-200 text-gray-700 rounded cursor-pointer">
                                {capitalizeWords(item)}
                              </span>
                            ))
                          : <span className="inline-block px-2 py-1 bg-gray-200 text-gray-700 rounded cursor-pointer">&nbsp;</span>} {/* Blank box if no data */}
                      </div>
                    </td>
                    <td className="px-3 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500 truncate w-32" onClick={() => handleProfileClick(row.company_id)}>
                        {row.ocean_label && row.ocean_label.length > 0
                          ? row.ocean_label.map((item, index) => (
                              <span key={`ocean-${index}`} className="inline-block px-2 py-1 bg-gray-200 text-gray-700 rounded cursor-pointer">
                                {capitalizeWords(item)}
                              </span>
                            ))
                          : <span className="inline-block px-2 py-1 bg-gray-200 text-gray-700 rounded cursor-pointer">&nbsp;</span>} {/* Blank box if no data */}
                      </div>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500 truncate w-[200px]" title={row.content}>
                        {new DOMParser().parseFromString(row.content, "text/html").body.textContent}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm w-[50px]">
                        {row.outreach_progress === null ? (
                          <span className="px-2 py-1 rounded-full bg-red-100 text-gray-600">
                            Not Started
                          </span>
                        ) : (
                          <span className="px-2 py-1 rounded-full bg-green-100 text-white-600">
                            {row.outreach_progress}
                          </span>
                        )}
                      </div>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap w-[50px]">
                      <span 
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          typeof row.email_confirmation_status_id === 'number'
                            ? getStatusStyle(row.email_confirmation_status_id).style
                            : ''
                        }`}
                      >
                        <span
                          className="w-2 h-2 rounded-full mr-2 mt-1.5"
                          style={{ backgroundColor: 'currentColor' }}
                        ></span>
                        {typeof row.email_confirmation_status_id === 'string'
                          ? row.email_confirmation_status_id // Show the text directly
                          : getStatusStyle(row.email_confirmation_status_id).text // Show mapped text
                        }
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <div className="flex space-x-3">
                        <button
                          onClick={() => handleGenerate(row.company_id)}
                          className={`rounded-md w-[80px] h-[25px] ${
                            campaignState === 'Confirm' || [2, 4, 5, 6, 7, 9, 10].includes(row.email_confirmation_status_id)
                              ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                              : 'bg-yellow-500 text-white hover:bg-yellow-600'
                          }`}
                          disabled={campaignState === 'Confirm' || [2, 4, 5, 6, 7, 9, 10].includes(row.email_confirmation_status_id)}
                        >
                          Generate
                        </button>

                        <button
                          onClick={() => handleReview(row.company_id)}
                          className={`rounded-md w-[80px] h-[25px] ${
                            campaignState === 'Confirm'  || [1, 4, 5, 6, 7, 8, 9, 10].includes(row.email_confirmation_status_id) //|| row.content === null
                              ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                              : 'bg-yellow-500 text-white hover:bg-yellow-600'
                          }`}
                          disabled={campaignState === 'Confirm'  || [1, 4, 5, 6, 7, 8, 9, 10].includes(row.email_confirmation_status_id)} //|| row.content === null
                        >
                          Review
                        </button>

                        <button
                          onClick={() => handleSend(row.company_id, row.outreach_progress)}
                          className={`flex justify-center items-center rounded-md w-[50px] h-[25px] ${
                            campaignState === 'Confirm' || [1, 7, 8, 9].includes(row.email_confirmation_status_id) //|| row.content === null
                              ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                              : 'bg-blue-500 text-white hover:bg-blue-600'
                          }`}
                          disabled={campaignState === 'Confirm' || [1, 7, 8, 9].includes(row.email_confirmation_status_id)} //|| row.content === null
                        >
                          <FaEnvelope
                            className={`text-lg ${
                              campaignState === 'Confirm' || [1, 7, 8, 9].includes(row.email_confirmation_status_id) //|| row.content === null
                                ? 'text-gray-500'
                                : 'text-white'
                            }`}
                          />
                        </button>

                        <button
                          onClick={() => handleSendLinked(row.company_id)}
                          className={`flex justify-center items-center rounded-md w-[50px] h-[25px] ${
                            !isLnkActive || campaignState === 'Confirm' || [1, 5, 6, 7, 8, 9].includes(row.email_confirmation_status_id) //|| row.content === null
                              ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                              : 'bg-blue-500 text-white hover:bg-blue-600'
                          }`}
                          disabled={
                            !isLnkActive || campaignState === 'Confirm' || [1, 5, 6, 7, 8, 9].includes(row.email_confirmation_status_id) //|| row.content === null
                          }
                        >
                          <FaLinkedin
                            className={`text-lg ${
                              !isLnkActive || campaignState === 'Confirm' || [1, 5, 6, 7, 8, 9].includes(row.email_confirmation_status_id) //|| row.content === null
                                ? 'text-gray-500'
                                : 'text-white'
                            }`}
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* DISC Modal */}
        <ExplainDISC isOpen={isModalOpen} closeModal={handleCloseDISCModal} />

        {/* OCEAN Modal */}
        <ExplainOCEAN isOpen={isOCEANModalOpen} closeModal={handleCloseOCEANModal} />

      </div>
      
      <PromptBox
        isVisible={showPromptBox}
        promptText={promptText}
        setPromptText={setPromptText}
        handleAutoSend={handleAutoSend}
        position={promptButtonPosition}
        promptBoxRef={promptBoxRef}
      />

      {/* Review UI*/}
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg md:w-[900px] md:h-[750px]">
            {/* Title */}
            <h3 className="text-xl font-bold mb-4">{getTitle()}</h3>
            {/* Subject */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Subject:</label>
              <input
                type="text"
                value={currentEmail.subject}
                onChange={(e) => handleChangeSubject(e.target.value)}
                className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-600"
              />
            </div>
            {/* Content */}
            <div className="mb-[50px]">
              <label className="block text-sm font-medium text-gray-700 mb-1">Content: </label>
              <ReactQuill
                key={currentIndex}
                theme="snow"
                value={currentEmail.body}
                onChange={handleChangeBody}
                modules={modules}
                className="md:h-[400px] h-[250px] rounded-lg"
              />
            </div>

            {/* Prev/Next Buttons */}
            <div className="flex justify-between items-center mb-4">
              <button
                onClick={handlePrev}
                className="px-3 py-1 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 flex items-center"
              >
                <FaArrowLeft size={16} className="mr-1" /> Prev
              </button>
              <span className="text-sm font-medium">{currentIndex + 1} / {emailData.length}</span>
              <button
                onClick={handleNext}
                className="px-3 py-1 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 flex items-center"
              >
                Next <FaArrowRight size={16} className="ml-1" />
              </button>
            </div>

            {/* Cancel/Update Buttons */}
            <div className="flex justify-end space-x-4 mt-[50px]">
            <button
                onClick={handleUpdateMail}
                className="px-4 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700"
              >
                Update
              </button>
              <button
                onClick={handleCancel}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-full hover:bg-gray-400"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {isPopUpAddContact && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-[700px] bg-white p-6 rounded-lg shadow-lg">
            <div className="flex items-center"> 
              <h2 className="text-xl font-bold mb-4">Add New Prospect</h2>
              <IconWithPopup className="mb-4" message={"Please provide at least the Company Name, Industry, Company Email and Reply Email for sending email"}/>
            </div>
            <form onSubmit={handleFormSubmit}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Left Column */}
                <div>
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-2">
                      Company Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="company_name"
                      value={newContact.company_name}
                      onChange={handleFormChange}
                      className="w-full px-3 py-2 border rounded"
                      placeholder="Your company name"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-2">
                      Industry <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="industry"
                      value={newContact.industry}
                      onChange={handleFormChange}
                      className="w-full px-3 py-2 border rounded"
                      placeholder="Your industry"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-2">
                      Company Email <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="email"
                      name="company_email"
                      value={newContact.company_email}
                      onChange={handleFormChange}
                      className="w-full px-3 py-2 border rounded"
                      placeholder="Your company email"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-2">Company LinkedIn</label>
                    <input
                      type="text"
                      name="company_linkedin"
                      value={newContact.company_linkedin}
                      onChange={handleFormChange}
                      className="w-full px-3 py-2 border rounded"
                      placeholder="Your company LinkedIn"
                    />
                  </div>
                </div>

                {/* Right Column */}
                <div>
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-2">Representative Name <span className="text-red-500">*</span> </label>
                    <input
                      type="text"
                      name="rep_name"
                      value={newContact.rep_name}
                      onChange={handleFormChange}
                      className="w-full px-3 py-2 border rounded"
                      placeholder="Your company Reply Name"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-2">Representative Email <span className="text-red-500">*</span> </label>
                    <input
                      type="email"
                      name="rep_email"
                      value={newContact.rep_email}
                      onChange={handleFormChange}
                      className="w-full px-3 py-2 border rounded"
                      placeholder="Your company Reply Email"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-2">Representative LinkedIn</label>
                    <input
                      type="text"
                      name="rep_linked"
                      value={newContact.rep_linked}
                      onChange={handleFormChange}
                      className="w-full px-3 py-2 border rounded"
                      placeholder="Your company Reply LinkedIn"
                    />
                  </div>
                </div>
              </div>

              {/* Buttons */}
              <div className="flex justify-end space-x-4 mt-4">
                <button
                  type="button"
                  onClick={handlePopUpClose}
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded-full"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700"
                  onClick={handleAddContact}
                >
                  Add
                </button>
              </div>
            </form>

          </div>
        </div>
      )}

      {/* Prompt Box UI */}
      {isPromptBoxOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-1/2 shadow-lg">
            {/* Box 1: Header */}
            <div className="flex justify-between items-center mb-4">
              <h1 className="text-xl font-bold">Prompt Box Selection</h1>
              <button className="text-gray-500 hover:text-gray-700" onClick={togglePromptPopup}>✖</button>
            </div>
            
            {/* Box 2: Instruction */}
            <p className="mb-4">Please select one of the two prompts to generate an email below:</p>
            
            {/* Box 3 & 4: Dynamic Prompt Selection */}
            {promptData.length > 0 ? (
              promptData.map((prompt, index) => (
                <div 
                  key={prompt.prompt_id} 
                  className={`border-4 p-4 mb-4 rounded-lg cursor-pointer ${selectedPrompt === prompt ? 'border-blue-500' : 'border-gray-300'}`}
                  onClick={() => handlePromptSelect(prompt)}
                >
                  <h2 className="text-lg font-semibold">
                    Email Prompt Generation {index + 1}
                  </h2>
                  <textarea 
                    className="w-full mt-2 p-2 border rounded-lg" 
                    rows="10"
                    value={prompt.prompt_content ? prompt.prompt_content.replace(/<br\s*\/?>/g, "\n") : ""}
                    readOnly // Remove this if editing is required
                  ></textarea>
                </div>
              ))
            ) : (
              <p className="text-gray-500">Loading prompts...</p>
            )}
            
            {/* Box 5: Selected Prompt Info & Buttons */}
            <div className="flex justify-between items-center mt-4">
              {/* Selected Prompt Info */}
              <p className="text-gray-700">
                {selectedPrompt 
                  ? `You have selected Prompt ${promptData.findIndex(p => p === selectedPrompt) + 1}`
                  : "You have not selected any Prompt"}
              </p>

              {/* Buttons */}
              <div className="flex gap-4">
                <button className="px-4 py-2 bg-gray-300 rounded-full" onClick={handlePromptCancel}>Cancel</button>
                <button 
                  className="px-4 py-2 bg-blue-500 text-white rounded-full disabled:opacity-50" 
                  onClick={generatePromptAction ? handleGenerateWithPrompt : handlePromptConfirm} 
                  disabled={!selectedPrompt}
                >
                  {generatePromptAction ? "Start Generate" : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedCampaign && (
        <CampaignSummary
          campaignId={selectedCampaign}
          onClose={() => setSelectedCampaign(null)}
        />
      )}

    </div>
    
  );
};

export default EmailStatus;