import React, { useState, useEffect, useRef } from 'react';
import {OnboardingHeaderPart, LargeImagePlaceholder} from '../../header/OnboardingHeader';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useVoiceInput } from '../onboardingPage/VoiceInput';
import { fetchAndReturnPCM16 } from '../onboardingPage/AvatarUtils';
import { SimliClient } from "simli-client";
import { FaMicrophone, FaMicrophoneSlash, FaSpinner } from 'react-icons/fa';
import videonothing from '../../assets/saynothing_female.mp4'
import video3 from '../../assets/page3_female_openai.mp4'
import video3_data from '../../assets/page3_female_openai_data.mp4'
import video3_v2 from '../../assets/page3_female_openai_v2.mp4'

const simliClient = new SimliClient();

const CampaignInitialize2 = () => {
  const [isPageReady, setIsPageReady] = useState(false);
  const [isLinkedInLoggedIn, setIsLinkedInLoggedIn] = useState(false);
  const navigate = useNavigate();
  const roboman_api = process.env.REACT_APP_ROBOMAN_API;
  const preRecordedRef = useRef(null);
  const audioRef = useRef(null);
  const videoRef = useRef(null);
  const Button = ({ className, onClick, text }) => (
    <button className={className} onClick={onClick}>
      {text}
    </button>
  );

  //
  const { startListening, stopListening, getTranscript,  isListening, browserSupportsSpeechRecognition, resetTranscript } = useVoiceInput();
  //
  const videoToUse = JSON.parse(localStorage.getItem("havedata")) === true ? video3_v2 : video3_v2;
  const [showPreRecorded, setShowPreRecorded] = useState(true);

  const fetchTextToSpeech = async (text_input, is_summary) => {
    try {
        const response = await fetch(`${roboman_api}/openai/text-to-speech`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                text_input,
                is_summary,
            }),
        });
  
        if (!response.ok) {
            console.error(`HTTP error! status: ${response.status}`);
            return null;
        }
  
        // Get the result from the response
        const result = await response.json();
  
        // Fetch the PCM16 data
        const audio8uintdata = await fetchAndReturnPCM16(result.image_url);
        return audio8uintdata
    } catch (error) {
        console.error('Error during text-to-speech fetch:', error);
        return null;
    }
  };

  const handleStartSimli = async () => {
    // Step 1: Initialize
    const SimliConfig = {
      apiKey: process.env.REACT_APP_SIMLI_API_KEY,
      faceID: process.env.REACT_APP_SIMLI_FACEID_FEMALE,
      handleSilence: true,
      maxSessionLength: 1800, // in seconds
      maxIdleTime: 200, // in seconds
      videoRef: videoRef,
      audioRef: audioRef,
      SimliURL: "s://api.simli.ai",
    };

    try {
      simliClient.Initialize(SimliConfig);
      console.log("Simli Client initialized");

      await simliClient.start();
      console.log("WebRTC started");

      setTimeout( async () => {
        const audioData = new Uint8Array(1000).fill(0);
        simliClient.sendAudioData(audioData);
      }, 4000);

    } catch (error) {
      console.error("Error in handleVoiceMode:", error);
    }
  }

  const handleCloseSimli = () => {
    simliClient.close();
  }

  useEffect(() => {

      const fetchUserDetails = async () => {
        const headers = {
          'accept': 'application/json',
          'Authorization': localStorage.getItem('access_token')
        };
  
        try {
          const response = await fetch(`${roboman_api}/users/me`, {
            method: 'GET',
            headers: headers,
          });
  
          if (response.ok) {
            const data = await response.json();
            if (data.unipile_account_id === null) {
              localStorage.setItem('lnk_act', false);
              setIsLinkedInLoggedIn(false);
              // await Swal.fire({
              //   icon: 'error',
              //   title: 'LinkedIn Login Failed',
              //   text: 'The LinkedIn login is unsuccessful. You cannot use to LinkedIn feature.',
              //   confirmButtonText: 'Okay',
              // })
            } else {
              localStorage.setItem('lnk_act', true);
              setIsLinkedInLoggedIn(true);
              // await Swal.fire({
              //   icon: 'success',
              //   title: 'LinkedIn Login Successful',
              //   text: 'The LinkedIn login was successful. You can use to LinkedIn feature.',
              //   confirmButtonText: 'Okay',
              // })
            }
          } else {
            localStorage.setItem('lnk_act', false);
            setIsLinkedInLoggedIn(false);
            // await Swal.fire({
            //   icon: 'error',
            //   title: 'Error',
            //   text: `Failed to fetch user details. You cannot use to LinkedIn feature.`,
            //   confirmButtonText: 'Okay',
            // })
          }
        } catch (error) {
          localStorage.setItem('lnk_act', false);
          setIsLinkedInLoggedIn(false);
          // await Swal.fire({
          //   icon: 'error',
          //   title: 'Error',
          //   text: `An error occurred: ${error.message}`,
          //   confirmButtonText: 'Okay',
          // })
        } finally {
          setIsPageReady(true); // Allow page rendering after SweetAlert interaction
        }
      };
  
      if (isPageReady===false) fetchUserDetails();
  }, []);

  useEffect(() => {
    if (isPageReady) {
      handleStartSimli();
    }
  }, [isPageReady]);

  const HandleUsingCampaignAssistance = () => {
    handleCloseSimli();
    console.log("Using Campaign Assistance");
    navigate('/useraichat')
    // Add your logic here
  };

  const HandleUsingManualFill = () => {
    handleCloseSimli();
    console.log("Using Manual Fill");
    navigate('/campfill')
    // Add your logic here
  };

  const HandleBack  = () => {
    handleCloseSimli();
    navigate('/dashboard')
    // Add your logic here
  };

  const PlanOption = ({ title, features, onChoose }) => (
    <div className="bg-gray-100 shadow-md rounded-lg p-4 flex flex-col items-center w-116 h-30 justify-between relative border-2 border-blue-500">
      {/* Center-Aligned Title */}
      <div className="flex justify-center w-full mb-4">
        <h3 className="text-lg font-semibold text-center">{title}</h3>
      </div>
      {/* Features Box */}
      <div className="flex justify-center items-center h-[200px] w-full bg-white p-4 rounded-md border border-gray-300 text-center">
        <p className="text-black-700">{features}</p>
      </div>
      {/* Center-Aligned Choose Button */}
      <div className="flex justify-center w-full mt-4">
        <Button
          onClick={onChoose}
          className="text-white bg-[#223F9E] min-w-[100px] py-2 px-4 font-semibold rounded-full"
          text="Choose"
        />
      </div>
    </div>
  );

  const SelectionBox = () => {
    
    const plans = [
      { 
        title: "Campaign Assistant", 
        features: ["Have a chat with Anna and she will create your campaign for you"],
        onChoose: HandleUsingCampaignAssistance
      },
      { 
        title: "Manual Fill", 
        features: ["Enter campaign details manually. Please fill out the following forms so we can build your campaign "],
        onChoose: HandleUsingManualFill
      },
    ];

    return (
      <main className="max-h-[600px] flex-grow flex flex-col justify-center rounded-lg items-center p-6 bg-white shadow-md">
        <h1 className="text-xl font-bold mb-6 text-center">Tell us about your situation</h1>
        <div className="min-h-[350px] text-sm flex space-x-4 mb-8">
          {plans.map((plan, index) => (
            <div key={index} className="flex-1">
              <PlanOption title={plan.title} features={plan.features} onChoose={plan.onChoose} />
            </div>
          ))}
        </div>
        {/* Right-Aligned Skip Button */}
        <div className="flex justify-end w-full">
          <Button
            onClick={HandleBack} // Note: Replace with actual navigation logic
            className="h-[40px] min-w-[150px] font-bold text-white bg-red-600 py-1 px-7 rounded-full border border-red-700"
            text="Skip"
          />
        </div>

        <div className="flex justify-center items-center w-full mt-5">
          <button
              className={`rounded-full p-4 text-white ${
                  isListening ? 'bg-red-500' : 'bg-gray-500'
              }`}
              disabled={isListening || isAvatarPlaying}
          >
              {isListening ? (
                  <FaMicrophone size={24} />
              ) : (
                  <FaMicrophoneSlash size={24} />
              )}
          </button>
        </div>
      </main>
    );
  };

  // for chat function
  const [inputMessage, setInputMessage] = useState('');
  const [response, setResponse] = useState('')
  const [isAvatarPlaying, setAvatarPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const handleSendMessage = async () => {
    if (isListening) {
      stopListening()
    }

    resetTranscript();

    try {
      
      // Fetch the full response from the server
      const response = await fetch(`${roboman_api}/campaign/initiation`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': localStorage.getItem('access_token'),
        },
        body: JSON.stringify({
          input_str: inputMessage,
        }),
      });
      let fullResponse = await response.text();
      // let fullResponse = response.data;
      // console.log(fullResponse)
      setResponse(fullResponse)
      console.log("response: ",fullResponse)
      // check if user has say something
      if (fullResponse.includes("Thank you for choosing Campaign Assistant")) {
        stopListening();
        HandleUsingCampaignAssistance();
      } else if (fullResponse.includes("Thank you for choosing Manual Fill")) {
        stopListening();
        HandleUsingManualFill();
      } else {
        await sendAudiotoSimli(fullResponse, false);
        
      }
      

  } catch (error) {
      console.log(error)
      console.error('Error:', error);
      let errorMessage = "Sorry, I encountered an error. Please try again.";
      if (error.response) {
          errorMessage = `Server error. Please try again later.`;
      } else if (error.request) {
          errorMessage = "No response from the server. Please check your internet connection.";
      } else {
          errorMessage = "An unexpected error occurred. Please try again.";
      }
  } finally {
      // setIsBotResponding(false);
  }

  }

  const sendAudiotoSimli = async (assistant, is_summary=true) => {
      try {
          const response = await fetch(`${roboman_api}/openai/text-to-speech`, {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Authorization': localStorage.getItem('access_token'),
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              text_input: assistant,
              is_summary: is_summary,
          }),
      });

      if (!response.ok) {
          console.error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("receive audio link:", result.image_url)
      //  Assuming the response contains an `audio_url` field
      
      // Attempt to play the audio automatically
      const audio8uintdata = await fetchAndReturnPCM16(result.image_url);
      console.log("audio data:", audio8uintdata)
      
      // Step 6: Send audio data to WebRTC as 6000 byte chunks
      const chunkSize = 6000;
      for (let i = 0; i < audio8uintdata.length; i += chunkSize) {
          const chunk = audio8uintdata.slice(i, i + chunkSize);
          simliClient.sendAudioData(chunk);
      }
      setAvatarPlaying(true)

      
      } catch (error) {
          console.error(error.message);
      } finally {
          // setIsLoading(false);
      }
  };

  const [messageSent, setMessageSent] = useState(false);
  const transcriptTimeoutRef = useRef(null);
  // Function to reset the timeout for auto-sending the message
  const resetAutoSendTimeout = () => {
    if (transcriptTimeoutRef.current) {
        clearTimeout(transcriptTimeoutRef.current); // Clear the existing timeout
    }

    transcriptTimeoutRef.current = setTimeout(async () => { // Use async for the callback
        if (inputMessage && inputMessage.trim() !== "" && !messageSent) { // Only send if there's actual text and not already sent
            try {
                
                await handleSendMessage(true); // Wait for handleSendMessage to complete
                setMessageSent(true); // Set the flag to true after sending
                console.log("Message sent");
                  // Clear the transcribed text after sending
                
            } catch (error) {
                console.error("Error in handleSendMessage:", error);
            }
        }
    }, 2500); // 3 second debounce
  };

  useEffect(() => {
      const transcript = getTranscript();
      console.log("New transcript:", transcript);
      console.log("Current inputMessage:", inputMessage);
      
      if (isListening) {
          if (transcript !== inputMessage) {
              console.log("Transcript changed, updating inputMessage");
              setInputMessage(transcript); // Update the input message
              setMessageSent(false); // Reset the sent flag when transcript changes
          } else if (transcript === inputMessage && !messageSent) {
              console.log("Transcript matches inputMessage, setting timeout");
              // Only reset the timeout if the transcript and inputMessage are the same and message hasn't been sent
              resetAutoSendTimeout();
          }

      } else {
          if (transcript){
              setInputMessage(transcript);
          }
          
      }
  }, [getTranscript, isListening, inputMessage, messageSent]);
  //

  useEffect(() => {
    simliClient.on("connected",() => {
        // setIsLoading(true);
        console.log("SimliClient has connected!");
        stopListening();
    });

    simliClient.on("disconnected", () => {
        // setConnected(false);
        console.log("SimliClient has disconnected!");
        stopListening();
    });

    simliClient.on("failed", () => {
        // setConnected(false);
        // setIsLoading(true);
        console.log("SimliClient has failed to connect!");
        stopListening();
    });

    // Listen for avatar speaking or silent events
    simliClient.on("speaking", () => {
        setAvatarPlaying(true);
        console.log("Avatar is speaking");
        stopListening();
        // Update state or perform actions related to avatar speaking
    });

    simliClient.on("silent", () => {
        console.log("Avatar is silent");
        setAvatarPlaying(false);
        setIsLoading(false);
        console.log("Pre Recorded", showPreRecorded)
        if (!showPreRecorded)
          {
            setTimeout(() => {
              startListening();
            }, 500);
          }

    });

    // Cleanup function to remove the event listener when the component unmounts or showPreRecorded changes
    // return () => {
    //   simliClient.off("silent");
    // };

  }, [showPreRecorded]);

  // useEffect(() => {
  //   if (!isListening && !showPreRecorded) {
  //     startListening();
  //   }
  // }, [isListening, showPreRecorded]);

  
  const handlePreRecordedEnd = () => {
    setShowPreRecorded(false);
    startListening() // Hide pre-recorded video
  };

  // If the page isn't ready yet, show a loading indicator or nothing
  if (!isPageReady) {
    return <div className="flex items-center justify-center min-h-screen bg-gray-100">Loading...</div>;
  }

  return (
    
    <div className="flex flex-col min-h-screen bg-white relative">

      {/* Header section */}
      <OnboardingHeaderPart />
      {!isPageReady ? (
        // Loading State UI
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
          <FaSpinner className="text-gray-600 text-4xl animate-spin" />
        </div>
      ) : (
        // Main Page Content
        <div className="flex flex-col items-center flex-grow pt-10 overflow-auto bg-gray-100 max-h-screen">
          <div className="flex flex-col lg:flex-row w-full max-w-[1200px] min-h-[600px] md:justify-center md:items-center">
            {/* Left Box */}
            <div className="w-[600px] flex flex-col items-start justify-start ml-10 mr-10">
              <div className="flex-grow flex items-center justify-center bg-transparent w-full rounded-xl h-[calc(100%-1rem)]">
                <div className="relative w-[500px] h-[500px]">
                  <div>
                    <video
                      ref={preRecordedRef}
                      className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-500 rounded-full ${
                        showPreRecorded ? "z-10" : "opacity-0"
                      }`}
                      onEnded={handlePreRecordedEnd}
                      autoPlay
                      playsInline
                    >
                      <source src={videoToUse} type="video/ogg" />
                    </video>
                  </div>
                  <div>
                    <video ref={videoRef} id="simli_video" autoPlay playsInline className="w-full h-full object-cover rounded-full"></video>
                    <audio ref={audioRef} id="simli_audio" autoPlay></audio>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Box */}
            <div className="md:w-[600px] md:h-[600px] bg-gray-100 flex flex-col justify-center items-center">
              <SelectionBox />
            </div>
          </div>

          {/* ✅ LinkedIn Status Message (Now Always at the Bottom) */}
          <div className="w-full flex justify-center mt-20 mb-5">
            <div
              className={`px-6 py-3 text-white font-bold rounded-full shadow-lg ${
                isLinkedInLoggedIn ? "bg-green-500" : "bg-red-500"
              }`}
            >
              {isLinkedInLoggedIn
                ? "The LinkedIn login is successful. You can use LinkedIn features."
                : "The LinkedIn login is not successful. You cannot use LinkedIn features."}
            </div>
          </div>
        </div>

      )}
    </div>
  );

};

export default CampaignInitialize2;
