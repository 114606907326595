// Import SweetAlert2 (make sure SweetAlert2 is installed in your project)
import Swal from "sweetalert2";


// Fetch campaign companies function
export async function fetchCampaignCompanies(campaignId, page, pageSize, authToken) {
  // Show SweetAlert loading modal
  Swal.fire({
    title: "Loading...",
    text: "Please wait while we fetch the data.",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    },
  });
  const roboman_api = process.env.REACT_APP_ROBOMAN_API;

  // API endpoint
  const url = `${roboman_api}/campaign/${campaignId}/companies/${page}/${pageSize}`;
  // console.log("Request URL:", url);
  try {
    // Fetch data
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: authToken,
      },
    });

    // Check response status
    if (!response.ok) {
      console.error(`HTTP error! status: ${response.status}`);
      Swal.close();
      return null;
    }

    // Parse the JSON response
    const data = await response.json();
    console.log("Successully take data")
    // Close the loading modal
    Swal.close();

    // Return the fetched data
    return data;
  } catch (error) {
    // Handle errors and show an alert
    Swal.fire({
      icon: "error",
      title: "Error!",
      text: `An error occurred: ${error.message}`,
    });

    console.error("Error fetching campaign companies:", error);
    return null;
  }
}

export const handleAddCompany = async () => {
  const roboman_api = process.env.REACT_APP_ROBOMAN_API;
  const campaignId = localStorage.getItem('campaign_id'); // Retrieve campaign_id from local storage

  if (!campaignId) {
    console.error('Campaign ID not found in local storage.');
    return;
  }

  const url = `${roboman_api}/campaign/${campaignId}/companies/add-multiple/v2`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': localStorage.getItem('access_token'), // Replace 'code' with the actual token
      },
    });

    if (!response.ok) {
      console.error(`HTTP error! status: ${response.status}`);
      return;
    }

    const data = await response.json();
    console.log('Successfull Add Company', data);
  } catch (error) {
    console.error('Error adding companies:', error);
  }
};