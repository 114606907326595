import React, { useEffect, useState } from 'react';
import {OnboardingHeaderPart} from '../../header/OnboardingHeader';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FiMapPin } from 'react-icons/fi';

const OnboardingCampaignMatching = () => {
    const navigate = useNavigate();
    //
    const [isLoading, setIsLoading] = useState(false);
    const roboman_api = process.env.REACT_APP_ROBOMAN_API;
    //
     
    const WelcomeString = () => {
        return (
        <div>
            <h1 className="text-4xl font-bold text-center mb-3">
            Campaign Information
            </h1>
            <div className="flex items-center justify-center text-lg">
                <FiMapPin className="text-blue-500 mr-2" />
                Location: {localStorage.getItem('location')}
            </div>
        </div>
        );
    };



    const TargetIndustry = () => {
        // if (!userData) return null;
        const industry = localStorage.getItem("industry");
        let industries = [];

        try {
            // Attempt to parse the industry field as JSON
            industries = industry ? JSON.parse(industry) : [];
          } catch {
            // If parsing fails, assume it's a single string
            industries = industry ? [industry] : [];
        }

        return (
            <div className="p-3 w-full h-full bg-white flex flex-col space-y-4 rounded-xl shadow-md items-center">
              {/* Title */}
              <div className="text-center text-lg font-bold">TARGET INDUSTRY</div>
        
              {/* Industry list container */}
              <div className="p-2 w-[90%] h-[280px] bg-white rounded-lg">
                <div className= "full flex flex-wrap gap-2 overflow-auto">
                    {industries.map((item, index) => (
                    <div
                        key={index}
                        className="bg-gray-100 px-3 py-2 rounded-lg shadow-md whitespace-nowrap h-[30px] flex items-center"
                    >
                        <strong>{item}</strong>
                    </div>
                    ))}
                    {industries.length === 0 && (
                    <div className="text-gray-500">No target industries specified</div>
                    )}
                </div>
              </div>
            </div>
          );
        };
    
    // ContentBox to display each company info
    const ContentBox = ({ company }) => {
      // Check if the company data is "empty"
      const isEmpty = !company.company_name && !company.industry && !company.company_contact.rep_name;
    
      return (
        <div className="p-4 w-[400px] bg-gray-100 rounded-lg">
          {isEmpty ? (
            // If it's an empty box, show nothing
            <div className="h-full w-full bg-transparent"></div>
          ) : (
            <>
              <h1 className="text-blue-800 text-xl font-bold mb-2">{company.company_name || "N/A"}</h1>
              <ul className="list-disc pl-5">
                <li className="text-md mb-1">Industry: {company.industry || "N/A"}</li>
                <li className="text-md mb-1">
                  LinkedIn:{" "}
                  <a
                    href={company.company_contact?.company_linkedin || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline"
                  >
                    {company.company_contact?.company_linkedin || "N/A"}
                  </a>
                </li>
                <li className="text-md mb-1">
                  Representative: {company.company_contact?.rep_name || "N/A"}
                </li>
                <li className="text-md mb-1">
                  Email:{" "}
                  <a
                    href={`mailto:${company.company_contact?.rep_email || ""}`}
                    className="text-blue-500 underline"
                  >
                    {company.company_contact?.rep_email || "N/A"}
                  </a>
                </li>
              </ul>
            </>
          )}
        </div>
      );
    };    

    const TargetClient = () => {
      const [result, setResult] = useState([]);
      const [currentPage, setCurrentPage] = useState(1);
      const itemsPerPage = 6; // 2 columns * 3 rows

      // Helper function to generate a single blank object
      const generateBlankObject = () => ({
        company_name: "",
        industry: "",
        company_contact: {
          company_linkedin: "",
          rep_name: "",
          rep_email: "",
        },
      });

      // Fetch data from the API
      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(`${roboman_api}/campaign/companies/match/v2`, {
              method: "POST",
              headers: {
                accept: "application/json",
                Authorization: localStorage.getItem("access_token"),
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                campaign_name: localStorage.getItem("campaign_name"),
                core_service: localStorage.getItem("core_service"),
                unique_selling_proposition: localStorage.getItem("unique_selling_proposition"),
                target_audience: localStorage.getItem("target_audience"),
                problem_solved: localStorage.getItem("problem_solved"),
                key_benefits: JSON.parse(localStorage.getItem("key_benefits")),
                primary_goal_of_outreach_campaign: localStorage.getItem("primary_goal_of_outreach_campaign"),
                ideal_client: JSON.parse(localStorage.getItem("ideal_client")),
                success_measurement: localStorage.getItem("success_measurement"),
                industry: JSON.parse(localStorage.getItem("industry")),
                location: localStorage.getItem("location"),
                must_have_info: ""
              }),
            });

            if (!response.ok) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Failed to find matching company with this campaign! Please try agian",
              });
              setResult(Array(6).fill(generateBlankObject())); // Show 6 blank boxes if the fetch fails
              return;
            }

            const data = await response.json();
            // If the result is null or empty, show 6 default boxes
            setResult(data?.length > 0 ? data : Array(6).fill(generateBlankObject()));
          } catch (error) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong while finding matching company with this campaign!",
            });
            setResult(Array(6).fill(generateBlankObject())); // Show 6 blank boxes in case of error
          }
        };

        fetchData();
      }, []);

      // Fill missing items to match a multiple of 6
      const padResult = (data) => {
        const paddingNeeded = itemsPerPage - (data.length % itemsPerPage || itemsPerPage);
        return [...data, ...Array(paddingNeeded).fill(generateBlankObject())];
      };

      const paddedResult = padResult(result);

      // Paginate the padded result
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const currentItems = paddedResult.slice(indexOfFirstItem, indexOfLastItem);

      const totalPages = Math.ceil(paddedResult.length / itemsPerPage);

      const handleNextPage = () => {
        if (currentPage < totalPages) {
          setCurrentPage((prev) => prev + 1);
        }
      };

      const handlePrevPage = () => {
        if (currentPage > 1) {
          setCurrentPage((prev) => prev - 1);
        }
      };

      return (
        <div className="p-3 bg-white flex flex-col space-y-4 rounded-xl h-full items-center shadow-md ">
          {/* Title */}
          <div className="text-center text-lg font-bold">TARGET CLIENTS</div>

          {/* Display current items with spacing */}
          <div
            className="w-full grid gap-4 p-2 grid-cols-1 sm:grid-cols-2 lg:grid-cols-2"
            style={{ gridAutoRows: "minmax(0, 1fr)" }}
          >
            {currentItems.map((company, index) => (
              <div key={index} className="flex justify-center">
                <ContentBox company={company} />
              </div>
            ))}
          </div>

          {/* Pagination */}
          <div className="flex justify-between w-full mt-4">
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
            >
              Prev
            </button>
            <div className="flex items-center">
              Page {currentPage} of {totalPages}
            </div>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </div>
      );
    };


    const ProfilesPart = () => {
        return (
          <div className="w-full max-w-[1300px] flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
            <div className="w-full lg:w-1/3 min-h-[400px]">
              <TargetIndustry />
            </div>
            <div className="w-full lg:w-2/3 min-h-[400px]">
              <TargetClient />
            </div>
          </div>
        );
    };

    const UserInfomationBoard = () => {
        return (
            <div className="flex-grow flex flex-col items-center space-y-8 py-4 px-4 md:px-8">
                {/* First Part */}
                <WelcomeString />
                {/* Second Part */}
                <ProfilesPart />
            </div>
        );
    };

    const Footer = () => {
        return (
        <div className='py-4 bg-white flex justify-end shadow-2xl'>
            <div className="mb-3 mt-3">
                <button 
                className="bg-[#223F9E] text-white font-semibold py-2 px-10 mr-[50px] rounded-full"
                onClick={handleNextClick}
                >
                Next
                </button>
            </div>
        </div>
        )
    }


    const handleNextClick = () => {
        // Add your logic here for what should happen when the Next button is clicked
        console.log("Next button clicked");
        // For example, you might want to navigate to the next page or submit the review
        // You can add navigation logic or API calls here
        navigate('/reviewinfo');
    };

    return (

    <div>
    {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded shadow-lg">
            <div className="flex items-center space-x-2">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
            <span className="text-lg font-medium">Loading, please wait...</span>
            </div>
        </div>
        </div>
    )}
    {/* Render user data or other components here */}
        <div className="flex flex-col min-h-screen w-full bg-gray-100">
            {/* This part is for the header section */}
            <OnboardingHeaderPart />
            {/* This part is for the main/body section */}
            <UserInfomationBoard />
            {/* Footer is placed here to ensure it stays at the bottom */}
            <Footer />
        </div>
    </div>   

  );
};

export default OnboardingCampaignMatching;
