import React, { useEffect, useState } from 'react';
import {OnboardingHeaderPart} from '../../header/OnboardingHeader';
import { useNavigate } from 'react-router-dom';
import { FaPen, FaStar } from 'react-icons/fa';

const OnboardingReviewCampaign = () => {
  const navigate = useNavigate();
  // const campaignparse_api = process.env.REACT_APP_CREATE_CAMPAIGN
  const roboman_api = process.env.REACT_APP_ROBOMAN_API;

  // const [campaignContents, setCampaignContents] = useState({});
  
  // useEffect(() => {
  //   const parseJSONSafely = (item) => {
  //     try {
  //       return JSON.parse(item);
  //     } catch (e) {
  //       return item;
  //     }
  //   };

  //   const contents = {
  //     "Core Service/Product": localStorage.getItem('core_service') || '',
  //     "Unique Selling Proposition (USP)": localStorage.getItem('unique_selling_proposition') || '',
  //     "Target Audience": localStorage.getItem('target_audience') || '',
  //     "Problem Solved": localStorage.getItem('problem_solved') || '',
  //     "Key Benefits": parseJSONSafely(localStorage.getItem('key_benefits')),
  //     "Primary Goal of Outreach Campaign": localStorage.getItem('primary_goal_of_outreach_campaign') || '',
  //     "Ideal Clients": localStorage.getItem('ideal_client') || '',
  //     "Success Measurement": parseJSONSafely(localStorage.getItem('success_measurement'))
  //   };
  //   setCampaignContents(contents);
  // }, []);

  const [showConstructPopup, setshowConstructPopup] = useState(false);

  
  useEffect(() => {
      setshowConstructPopup(true); // Show the popup when the component is loaded
      console.log("show guidance")
  }, []);

  const handleNotificationConfirm = () => {
    setshowConstructPopup(false)
  };


  const EditPopup = ({ title, content, onCancel, onAccept }) => {
    const [editedContent, setEditedContent] = useState(
      Array.isArray(content) ? content.join('\n') : content
    );
  
    return (
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
        <div className="bg-white p-6 rounded-lg shadow-xl w-96">
          <h2 className="text-xl font-bold mb-4">{title}</h2>
          <textarea
            className="w-full h-40 p-2 border rounded mb-4"
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
          />
          <div className="flex justify-end">
            <button
              className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded"
              onClick={() => onAccept(title, editedContent)}
            >
              Accept
            </button>
          </div>
        </div>
      </div>
    );
  };

  // InfoBox Component
  const InfoBox = ({ title, content, onEdit }) => {
    return (
      <div className="w-full sm:w-[250px] md:w-[300px] h-[250px] bg-white shadow-md rounded-lg overflow-hidden flex flex-col">
        <div className="bg-white p-3 sm:p-4 flex justify-between items-center">
          <div className="flex items-center">
          <div className="w-8 h-8 sm:w-10 sm:h-10 rounded-full flex items-center justify-center mr-2">
            <FaStar className="text-blue-500 text-xs sm:text-sm" size={30}/>
          </div>
            <div className="text-xs sm:text-sm font-bold">{title}</div>
          </div>
          <button
            onClick={() => onEdit(title, content)}
            className="text-gray-600 hover:text-blue-500 transition-colors duration-200"
          >
            <FaPen size={14} />
          </button>
        </div>
        
        <div className="flex-grow p-3 sm:p-4 overflow-y-auto border-t">
          {Array.isArray(content) ? (
            <ul className="text-gray-700 text-xs sm:text-sm list-disc pl-4 sm:pl-5">
              {content.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-700 text-xs sm:text-sm">{content}</p>
          )}
        </div>
      </div>
    );
  };

  const USPEditPopup = ({ title, usp_user, onCancel, onAccept }) => {
    const [userUSP, setUserUSP] = useState(usp_user);
    const [aiUSP, setAIUSP] = useState(localStorage.getItem('suggest_usp')); // You'll need to replace this with actual AI-generated content
    return (
      <div className="fixed inset-0 bg-gray-300 bg-opacity-75 flex items-center justify-center">
        <div className="bg-white p-6 rounded-lg shadow-xl w-3/4 max-w-4xl">
          <h2 className="text-xl font-bold mb-4">{title}</h2>
          <div className="flex space-x-4">
            {/* User USP Box */}
            <div className="flex-1">
              <h3 className="font-bold mb-2">User USP</h3>
              <textarea
                className="w-full h-40 p-2 border rounded mb-4"
                value={userUSP}
                onChange={(e) => setUserUSP(e.target.value)}
              />
              <div className="flex justify-end">
                <button
                  className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                  onClick={() => onAccept(title, userUSP, 'user')}
                >
                  Accept
                </button>
              </div>
            </div>
            {/* AI USP Box */}
            <div className="flex-1">
              <h3 className="font-bold mb-2">USP from AI</h3>
              <textarea
                className="w-full h-40 p-2 border rounded mb-4"
                value={aiUSP}
                onChange={(e) => setAIUSP(e.target.value)}
              />
              <div className="flex justify-end">
                <button
                  className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                  onClick={() => onAccept(title, aiUSP, 'ai')}
                >
                  Accept
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // USPBox Component
  const USPBox = ({ title, content, onEdit }) => {
    return (
      <div className="w-full sm:w-[250px] md:w-[300px] h-[250px] bg-white shadow-md rounded-lg overflow-hidden flex flex-col">
        <div className="bg-white p-3 sm:p-4 flex justify-between items-center">
          <div className="flex items-center">
          <div className="w-8 h-8 sm:w-10 sm:h-10 rounded-full flex items-center justify-center mr-2">
            <FaStar className="text-blue-500 text-xs sm:text-sm" size={30}/>
          </div>
            <div className="text-xs sm:text-sm font-bold">{title}</div>
          </div>
          <button
            onClick={() => onEdit(title, content)}
            className="text-gray-600 hover:text-blue-500 transition-colors duration-200"
          >
            <FaPen size={14}/>
          </button>
        </div>
        <div className="flex-grow p-3 sm:p-4 overflow-y-auto border-t">
          <p className="text-gray-700 text-xs sm:text-sm">{content}</p>
        </div>
      </div>
    );
  };

  const ProvidedInfo = () => {
    const [campaignContents, setCampaignContents] = useState({});
    const [editingContent, setEditingContent] = useState(null);
    
    useEffect(() => {
      const parseJSONSafely = (item) => {
        try {
          return JSON.parse(item);
        } catch (e) {
          return item;
        }
      };
      
      localStorage.setItem('selected_usp',localStorage.getItem('unique_selling_proposition'))

      const contents = {
        "Core Service/Product": localStorage.getItem('core_service') || '',
        "Unique Selling Proposition (USP)": localStorage.getItem('selected_usp') || '',
        "Target Audience": localStorage.getItem('target_audience') || '',
        "Problem Solved": localStorage.getItem('problem_solved') || '',
        "Key Benefits": parseJSONSafely(localStorage.getItem('key_benefits')),
        "Primary Goal of Outreach Campaign": localStorage.getItem('primary_goal_of_outreach_campaign') || '',
        "Ideal Clients": parseJSONSafely(localStorage.getItem('ideal_client')) || '',
        "Success Measurement": parseJSONSafely(localStorage.getItem('success_measurement'))
      };
      setCampaignContents(contents);
    }, []);

    const handleEdit = (title, content) => {
      setEditingContent({ title, content });
    };
  
    const handleCancel = () => {
      setEditingContent(null);
    };
  
    const handleAccept = (title, newContent, source = null) => {
      setCampaignContents((prev) => ({
        ...prev,
        [title]: Array.isArray(prev[title]) ? newContent.split('\n') : newContent,
      }));
      setEditingContent(null);
  
      // Update localStorage
      if (title === "Unique Selling Proposition (USP)") {
        // localStorage.setItem('unique_selling_proposition', newContent);
        if (source === 'user') {
          // Save user input to localStorage
          localStorage.setItem('selected_usp', newContent);
        } else if (source === 'ai') {
          // Save AI generated content to localStorage
          localStorage.setItem('selected_usp', newContent);
        }
      } else {
        if (Array.isArray(campaignContents[title])) {
          localStorage.setItem(title.toLowerCase().replace(/ /g, '_'), JSON.stringify(newContent.split('\n')));
        } else {
          localStorage.setItem(title.toLowerCase().replace(/ /g, '_'), newContent);
        }
      }
    };

    return (
      <div>
        <h2 className="py-5 text-2xl font-bold mb-2 text-center">
          Ready to Launch: Your Campaign, Tailored to Your Goals
        </h2>
        <div className="px-4 sm:px-6 md:px-8 lg:px-10">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-6 lg:gap-8 xl:gap-8 justify-items-center">
            {Object.entries(campaignContents).map(([title, content], index) => (
              title === "Unique Selling Proposition (USP)" ? (
                <USPBox key={index} title={title} content={content} onEdit={handleEdit} />
              ) : (
                <InfoBox key={index} title={title} content={content} onEdit={handleEdit} />
              )
            ))}
          </div>
        </div>
        {editingContent && (
          editingContent.title === "Unique Selling Proposition (USP)" ? (
            <USPEditPopup
              title={editingContent.title}
              usp_user={localStorage.getItem('unique_selling_proposition')}
              onCancel={handleCancel}
              onAccept={handleAccept}
            />
          ) : (
            <EditPopup
              title={editingContent.title}
              content={editingContent.content}
              onCancel={handleCancel}
              onAccept={handleAccept}
            />
          )
        )}
      </div>
    );
  };

  const InformationPart = () => {
    return (
      <div className="flex-grow flex flex-col items-center space-y-8 py-4">
        {/* First Part */}
        <ProvidedInfo />
      </div>
    );
  };

  const handleNextClick = () => {
    // Add your logic here for what should happen when the Next button is clicked
    console.log("Next button clicked");
    // For example, you might want to navigate to the next page or submit the review
    // You can add navigation logic or API calls here
    navigate('/new-campaign');
  };

  const Footer = () => {
    return (
      <div className='py-4 bg-white flex justify-end shadow-2xl'>
          <div className="mb-3 mt-3">
            <button 
              className="bg-[#223F9E] text-white font-semibold py-2 px-10 mr-[50px] rounded-full"
              onClick={handleNextClick}
            >
              Next
            </button>
          </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
        {/* This part is for the header section */}
        <OnboardingHeaderPart />

        {/* This part is for the main/body section */}
        <InformationPart />

        {/* Footer is placed here to ensure it stays at the bottom */}
        <Footer />

        {showConstructPopup && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
              <div className="bg-white rounded-lg shadow-lg w-3/5 max-w-2xl mt-1 max-h-[800px]">
              <div className="w-full bg-gray-50 h-[70px] border rounded-md flex items-center justify-center">
                  <div className="w-full flex justify-between items-center pl-8 pr-8">
                  {/* <h2 className="text-2xl font-bold">Instruction</h2> */}
                  {/* <button onClick={handleExit} className="text-gray-500 hover:text-gray-700 text-2xl">&times;</button> */}
                  </div>
              </div>
              <div>
                  <hr className="border-t-1 border-gray-100 mb-4 w-full" />
              </div>
              <div className="space-y-7 overflow-y-auto flex items-center justify-center flex-col mb-8">
                  <p className="text-gray-700 text-left ml-8 mr-8">
                  Thank you for assist with us to complete your campaign. We understood your information and our system assist you to improve that. Here are your <strong> Optimized Unique Selling Proposition </strong>. 
                  </p>
                  <div className="w-full h-[50%] flex items-center justify-center">
                    <textarea
                      className="w-[90%] px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-24 resize-none bg-gray-100"
                      value={localStorage.getItem("suggest_usp")}
                    />
                  </div>


                  <p className="text-gray-700 text-left ml-8 mr-8">
                  If you want to review and change any infomation, please press the button <FaPen size={14} className="inline" /> to edit the information.
                  </p>            
                  <div className="flex justify-center space-x-6">
                      <button className="px-4 py-2 rounded-full text-white rounded-full bg-[#223F9E]" onClick={handleNotificationConfirm}>
                          Understand
                      </button>
                  </div>
              </div>
              </div>
          </div>
        )}
    </div>
  );
};

export default OnboardingReviewCampaign;
