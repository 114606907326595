import React, { useState, useEffect, useRef, useCallback } from 'react';
import HeaderMainPage from '../../header/HeaderMainPage';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import { useVoiceInput } from './VoiceInput';
import profileImage from '../../assets/profile.png';
import AIimage from '../../assets/female_avatar.png';
import { FaPlay, FaPause, FaMicrophone, FaMicrophoneSlash, FaSpinner, FaVolumeUp, FaVolumeMute } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { fetchAndReturnPCM16 } from './AvatarUtils'; 
import { SimliClient } from "simli-client";

const simliClient = new SimliClient();

const UserAIChatUI = () => {
    const navigate = useNavigate();
    const [messages, setMessages] = useState([]);
    const [messagePairs, setMessagePairs] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [showButtons, setShowButtons] = useState(false);
    const [isStreaming, setIsStreaming] = useState(false);
    const [isBotResponding, setIsBotResponding] = useState(false);
    const [isChatDisabled, setIsChatDisabled] = useState(false);
    const [campaignName, setCampaignName] = useState('');
    const [coreService, setCoreService] = useState('');
    const [targetAudience, setTargetAudience] = useState('');
    const [uniqueSellingProposition, setUniqueSellingProposition] = useState('');
    const [problemSolved, setProblemSolved] = useState('');
    const [keyBenefits, setKeyBenefits] = useState('');
    const [primaryGoalOfOutreachCampaign, setPrimaryGoalOfOutreachCampaign] = useState('');
    const [idealClient, setIdealClient] = useState('');
    const [successMeasurement, setSuccessMeasurement]  = useState('');
    const [location, setLocation] = useState('');
    const [industry, setIndustry] = useState('');
    const [suggest_usp, setSuggestUSP] = useState('');
    const [fullResponse, setFullResponse] = useState('');
    const messagesEndRef = useRef(null);
    const [showSummary, setShowSummary] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    
    const audioRef = useRef(null);
    
    // voice mode
    const [isVoiceMode, setIsVoiceMode] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [transcribedText, setTranscribedText] = useState("");
    const [isPlaying, setIsPlaying] = useState(false);
    const [isAvatarPlaying, setAvatarPlaying] = useState(false);
    // const [playingOpening, setPlayingOpening] = useState(true)
    // const [chathistory, setChatHistory] = useState('')
    const { startListening, stopListening, getTranscript,  isListening, browserSupportsSpeechRecognition, resetTranscript } = useVoiceInput();

    //
    const videoRef = useRef(null);

    //
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const audioContext = useRef(null);
    const [startWebRTC, setStartWebRTC] = useState(false);
    const [isConnected, setConnected] = useState(false)
    const [isWaitingScreen, setWaitingScreen] = useState(true)
 

    const roboman_api = process.env.REACT_APP_ROBOMAN_API;

    // State to manage mute/unmute
    const [isMuted, setIsMuted] = useState(false);
    const [isPause, setIsPause] = useState(false);
    const [isVoiceInputMode, setVoiceInputMode] = useState(true)

    useEffect(() => {
        if (isWaitingScreen) {
          handleVoiceMode();
          // setWaitingScreen(false);
        }
        
      }, [isWaitingScreen]);

    useEffect(() => {
        console.log("State Update:");
        console.log("isListening:", isListening);
        console.log("isPlaying:", isPlaying);
        console.log("showButtons:", showButtons);
        console.log("Avatar Mode:", isVoiceMode);
        console.log("Avatar Loading:", isLoading);
        console.log("Start WebRTC:", startWebRTC);
        console.log("Chat Pause:", isPause);
        console.log("Input Mode:", isVoiceInputMode);
        console.log("Avatar Connecting:", isConnected)
    }, [isListening, isPlaying, showButtons, isVoiceMode, audioUrl, isPause, isVoiceInputMode]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const formatList = (list) => {
        if (Array.isArray(list)) {
            return list.map((item) => ` ${item}.`).join('\n');
          }
          return list; // Return as is if not a list
    };

    const getMessageResponse = async (inputMessage) => {
        try {
            const response = await axios({
                method: 'post',
                url: `${roboman_api}/conversation/message`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': localStorage.getItem('access_token'),
                },
                data: {
                    conversation_id: localStorage.getItem('conversation_id'),
                    message_content: inputMessage,
                    model_id: 1,
                },
            });
    
            // Assuming the response is a string, you can return the response directly.
            return response.data;  // Adjust if the result is inside a nested object, e.g., response.data.result
        } catch (error) {
            console.error("Error fetching message response:", error);
            // throw error;  // Handle the error as needed
        }
    } 

    useEffect( () => {
        const storedCampaignName = localStorage.getItem('campaign_name') || '';
        const storedCoreService = localStorage.getItem('core_service') || '';
        const storedTargetAudience = localStorage.getItem('target_audience') || '';
        const storedUniqueSellingProposition = localStorage.getItem('unique_selling_proposition') || '';
        const storedProblemSolved = localStorage.getItem('problem_solved') || '';
        const storedKeyBenefits = JSON.parse(localStorage.getItem('key_benefits')) || '';
        const storedPrimaryGoalOfOutreachCampaign = localStorage.getItem('primary_goal_of_outreach_campaign') || '';
        const storedIdealClient = JSON.parse(localStorage.getItem('ideal_client')) || '';
        const storedSuccessMeasurement = localStorage.getItem('success_measurement') || '';
        const storedMessagePairs = JSON.parse(localStorage.getItem('messagePairs')) || [];
        
        
        setCampaignName(storedCampaignName);
        setCoreService(storedCoreService);
        setTargetAudience(storedTargetAudience);
        setUniqueSellingProposition(storedUniqueSellingProposition);
        setProblemSolved(storedProblemSolved);
        setKeyBenefits(storedKeyBenefits);
        setPrimaryGoalOfOutreachCampaign(storedPrimaryGoalOfOutreachCampaign);
        setIdealClient(storedIdealClient);
        setSuccessMeasurement(storedSuccessMeasurement);
        setMessagePairs(storedMessagePairs);
        const greeting_prompt = `This is a started message from user. Username is ${localStorage.getItem('nickname')}. Please return a greeting message in the happiness tone, no icon for respect, asking for her situation and provide help. For example: Hi ${localStorage.getItem('nickname')}, how are you today? I hope you always feel fine and happy. How can I help you today?.`

        if (storedCampaignName && storedCoreService && storedTargetAudience &&
            storedUniqueSellingProposition && storedProblemSolved && storedKeyBenefits &&
            storedPrimaryGoalOfOutreachCampaign && storedIdealClient && storedSuccessMeasurement) {
            // const summaryMessage = Here are your summary information: 
            // **Campaign Name**: ${storedCampaignName}
            // **Core Service**: ${storedCoreService}
            // **Target Audience**: ${storedTargetAudience}
            // **Unique Selling Proposition**: ${storedUniqueSellingProposition}
            // **Problem Solved**: ${storedProblemSolved}
            // **Key Benefits**: ${storedKeyBenefits}
            // **Primary Goal of Outreach Campaign**: ${storedPrimaryGoalOfOutreachCampaign}
            // **Ideal Client**: ${storedIdealClient}
            // **Success Measurement**: ${storedSuccessMeasurement};
            // setMessages([{ sender: 'bot', text: summaryMessage }]);
            // setShowButtons(true);
            // setIsChatDisabled(true);
            const fetchhistory = async () => {
                const hisdata = await updatechathistory();
                const chathis = historydatatochat(hisdata);
                setMessages(chathis)

            }
            fetchhistory();
            console.log("All field present")
        } else if (localStorage.getItem("conversation_id") === null) {
            
            stopListening()
            const fetchGreetingText = async () => {
                await getConversationId();
                const greeting_text = await getMessageResponse(greeting_prompt); // await the result

                setMessages([{ sender: 'bot', text: greeting_text }]);  // Set the message once data is fetched
                // await sum_and_speech(greeting_text, false);  // Text-to-speech conversion
                simulateStreaming(greeting_text);  // Modified streaming
            };


            fetchGreetingText();

        } else {
            const fetchhistory = async () => {
                const hisdata = await updatechathistory();
                const chathis = historydatatochat(hisdata);
                setMessages(chathis)

            }
            fetchhistory();
            // let continue_text = "Let's continue to chat"
            // simulateStreaming(continue_text);
        }
            
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    // This useCallback ensures that the function is only recreated when its dependencies change
    const checkAllFieldsFilled = useCallback(() => {
        return campaignName && coreService && targetAudience && uniqueSellingProposition && problemSolved && keyBenefits && primaryGoalOfOutreachCampaign && idealClient && successMeasurement;
    }, [campaignName, coreService, targetAudience, uniqueSellingProposition, problemSolved, keyBenefits, primaryGoalOfOutreachCampaign, idealClient, successMeasurement]);

    // This useEffect runs whenever the campaign info changes, updating the UI accordingly
    useEffect(() => {
        if (checkAllFieldsFilled() && showSummary) {
            setShowButtons(true);
            setIsChatDisabled(true);
            const welcomeMessage = `Here are your summary information: 
            **Campaign Name**: ${campaignName}
            **Core Service/Product**: ${coreService}
            **Target Audience**: ${targetAudience}
            **Unique Selling Proposition**: ${uniqueSellingProposition}
            **Problem Solved**: ${problemSolved}
            **Key Benefits**: ${formatList(keyBenefits)}
            **Primary Goal of Outreach Campaign**: ${primaryGoalOfOutreachCampaign}
            **Ideal Client**: ${formatList(idealClient)}
            **Success Measurement**: ${successMeasurement}`;
            setMessages(prevMessages => [...prevMessages, { sender: 'bot', text: welcomeMessage }]);
        } else {
            setShowButtons(false);
            setIsChatDisabled(false);
        }
    }, [checkAllFieldsFilled]);


    const hasAllFields = (message) => {
        const requiredFields = [
            "Campaign Name",
            "Service",
            "Product",
            "Audience",
            "Unique Selling Proposition",
            "Problem Solved",
            "Benefits",
            "Primary Goal",
            "Main Objective",
            "Ideal Client",
            "Success Measurement",
            "Success Metric"
        ];
    
        const threshold = 4; // At least half required fields
        let matchCount = 0;
    
        for (let field of requiredFields) {
            // Make the match case-insensitive and allow partial matching
            const regex = new RegExp(field.replace(/[-/]/g, ".?"), "i"); // Handle slight variations
            if (regex.test(message)) {
                matchCount++;
            }
        }
    
        return matchCount >= threshold;
    };

    // This useEffect handles the extraction of campaign info once the full response is received
    useEffect(() => {
        if (!isStreaming && fullResponse) {
            const lastMessage = messages[messages.length - 1];

            // extractCampaignInfo(lastMessage.text)
            // extractCampaignInfo(messagePairs)
            console.log("check status", hasAllFields(lastMessage.text))
            if (lastMessage && lastMessage.sender === 'bot' && hasAllFields(lastMessage.text)){
                // The .then() ensures that fullResponse is only cleared after extractCampaignInfo completes
                // console.log("extract data")

                extractCampaignInfo(lastMessage.text).then(() => {
                    setFullResponse('');
                    setShowButtons(true);
                    setIsChatDisabled(true);
                    setShowSummary(true);
                    stopListening();
                    // 
                })
            } else {
                setFullResponse('');
            }
        }
    }, [isStreaming, fullResponse, messages, messagePairs]);



    const getConversationId = async () => {
        try {
            const response = await fetch(`${roboman_api}/conversation/new`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': localStorage.getItem('access_token'),
                },
                body: JSON.stringify({
                    // Add any necessary data for this API call
                }),
            });

            if (!response.ok) {
                console.error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            
            if (data.conversation_id) {
                localStorage.setItem('conversation_id', data.conversation_id);
            }
        } catch (error) {
            console.error('Error getting conversation ID:', error);
        }
    };

    const extractCampaignInfo = async (text) => {
        try {
            const response = await fetch(`${roboman_api}/llm/campaign/parser`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('access_token'),
                },
                body: JSON.stringify({
                    input_str: text
                }),
            });

            if (!response.ok) {
                console.error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            
            if (data) {
                // console.log(data);
                localStorage.setItem('campaign_name', data.campaign_name);
                localStorage.setItem('core_service', data.core_service);
                localStorage.setItem('unique_selling_proposition', data.unique_selling_proposition); // must be unique_selling_point
                localStorage.setItem('target_audience', data.target_audience);
                localStorage.setItem('problem_solved', data.problem_solved);
                localStorage.setItem('key_benefits', JSON.stringify(data.key_benefits));
                localStorage.setItem('primary_goal_of_outreach_campaign', data.primary_goal_of_outreach_campaign);
                localStorage.setItem('ideal_client', JSON.stringify(data.ideal_client));
                localStorage.setItem('success_measurement', data.success_measurement);
                localStorage.setItem('suggest_usp', data.suggest_unique_selling_point); // must be suggest_unique_selling_point
                localStorage.setItem('location', data.location);
                localStorage.setItem('industry', JSON.stringify(data.industry));
                
                // Return a Promise to ensure all state updates are complete before moving on
                return new Promise((resolve) => {
                    setCampaignName(data.campaign_name);
                    setCoreService(data.core_service);
                    setTargetAudience(data.target_audience);
                    setUniqueSellingProposition(data.unique_selling_proposition);
                    setProblemSolved(data.problem_solved);
                    setKeyBenefits(data.key_benefits);
                    setPrimaryGoalOfOutreachCampaign(data.primary_goal_of_outreach_campaign);
                    setIdealClient(data.ideal_client);
                    setSuccessMeasurement(data.success_measurement);
                    setLocation(data.location);
                    setIndustry(data.industry);
                    setSuggestUSP(data.suggest_unique_selling_point);
                    resolve();
                });
            }
        } catch (error) {
            console.error('Error extracting campaign info:', error);
        }
    };

    const updatechathistory = async () => {
        const convid = localStorage.getItem('conversation_id')
        try {
            const response = await fetch(`${roboman_api}/conversation/${convid}`, {
              method: 'GET',
              headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.getItem('access_token'),
              },
            });
    
            if (!response.ok) {
                console.error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();

            // setChatHistory(data);
            return data
          } catch (error) {
            setError(error.message);
          }
    };

    // Function to convert API response into the desired format
    const historydatatochat = (data) => {
        const history = data.flatMap((item, index) => {
            const userMessage = item.message_data.user;
            const botMessage = item.message_data.assistant;
    
            // Skip the first user's message (index === 0)
            const messages = [];
            if (index !== 0) {
                messages.push({ sender: 'user', text: userMessage });
            }
            messages.push({ sender: 'bot', text: botMessage });
    
            return messages;
        });
    
        return history;
    };

    const sum_and_speech = async (assistant, is_summary=true, isAvatarMode=false) => {
        
        // const datachathistory = await updatechathistory();
        // const latestMessage = datachathistory[datachathistory.length - 1];
        // // console.log(latestMessage)

        // if (!latestMessage) {
        //     setError('No messages available.');
        //     return;
        // }

        // const { message_data } = latestMessage;
        // const { assistant } = message_data;

        try {
            const response = await fetch(`${roboman_api}/openai/text-to-speech`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                text_input: assistant,
                is_summary: is_summary,
            }),
        });

        if (!response.ok) {
            console.error(`HTTP error! status: ${response.status}`);
        }

        

        const result = await response.json();
        setAudioUrl(result.image_url);
        console.log("receive audio link:", result.image_url)
        //  Assuming the response contains an `audio_url` field
        
        
        // Attempt to play the audio automatically
        if (!isAvatarMode && audioRef.current) {
            audioRef.current.play().catch(error => {
                console.error('Autoplay failed:', error);
            });
        }
        console.log("avatar mode:", isAvatarMode)
        if (isAvatarMode) {
            const audio8uintdata = await fetchAndReturnPCM16(result.image_url);
            console.log("audio data:", audio8uintdata)
            // const fetchedHlsUrl = await simliapiFetchAPI(base64Audio);
            setAvatarPlaying(true)

            // Step 6: Send audio data to WebRTC as 6000 byte chunks
            const chunkSize = 6000;
            for (let i = 0; i < audio8uintdata.length; i += chunkSize) {
                const chunk = audio8uintdata.slice(i, i + chunkSize);
                simliClient.sendAudioData(chunk);
            }
        }
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSendMessage = async (voiceMode = false) => {
        const summary = !voiceMode;
        if (inputMessage.trim() === '' || isBotResponding) return;
    
        if (isListening) {
            stopListening();
        }
    
        setIsBotResponding(true);
        setMessages(prev => [...prev, { sender: 'user', text: inputMessage }]);
        resetTranscript();
        setInputMessage(''); // Clear input message after sending
        setShowButtons(false);
        setIsStreaming(true);
        setFullResponse('');
        
         // Add a waiting message from the bot to indicate it's processing
        setMessages(prev => [...prev, { sender: 'bot', text: 'Loading...' }]);
  
        try {
            let fullResponse = '';
            
            // Fetch the full response from the server
            const response = await axios({
                method: 'post',
                url: `${roboman_api}/conversation/message`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': localStorage.getItem('access_token'),
                },
                data: {
                    conversation_id: localStorage.getItem('conversation_id'),
                    message_content: inputMessage,
                    model_id: 1,
                },
            });
            // console.log("response: ", response)
            // console.log("response data: ", response.data)
            fullResponse = response.data; // Adjust according to your API response structure
            // console.log("message: ", fullResponse)
            setFullResponse(fullResponse);
    
            // Start sum_and_speech
            console.log('Start summarizing and speaking');
            console.log("avatar on mode:", isVoiceMode)

            
            const check = hasAllFields(fullResponse)
            console.log("Response has all field:", check)
            if (check)
            {
                if (voiceMode===false){
                    const summary_response = "Thank you for your information. Here are our summary, please review what we have done. After receiving the summary, we will have two way to continue. If you have some field need to change, please press Add More Detail Button. If not, please press Confirm and Process to got to the next page."
                    await sum_and_speech(summary_response, false, isVoiceMode);
                }
                else {
                    const summary_response = "Thank you for your information. Here are our summary, please review what we have done. After receiving the summary, we will have two way to continue. If you have some field need to change, please press Continue Speaking Button. If not, please press Confirm and Process to got to the next page."
                    await sum_and_speech(summary_response, false, isVoiceMode);
                }
                setShowButtons(true);
                stopListening();
                simulateStreaming(fullResponse);
            } else {
                await sum_and_speech(fullResponse, summary, isVoiceMode);
    
                // Simulate streaming text while `sum_and_speech` is running
                simulateStreaming(fullResponse);
                // console.log("finish simulate")

            }

    
            setMessagePairs(prev => {
                const updatedPairs = Array.isArray(prev) ? [...prev, [inputMessage, fullResponse]] : [[inputMessage, fullResponse]];
                localStorage.setItem('messagePairs', JSON.stringify(updatedPairs));
                return updatedPairs;
            });
    
            // if (checkAllFieldsFilled()) {
            //     setShowButtons(true);
            //     setIsChatDisabled(true);
            // }

            // Re-enable listening only if Voice Mode is true
            // if (voiceMode && !isPlaying) {
            //     startListening(); // Ensure to start listening if Voice Mode is true
            // }
        } catch (error) {
            console.log(error)
            console.error('Error:', error);
            let errorMessage = "Sorry, I encountered an error. Please try again.";
            if (error.response) {
                errorMessage = `Server error. Please try again later.`;
            } else if (error.request) {
                errorMessage = "No response from the server. Please check your internet connection.";
            } else {
                errorMessage = "An unexpected error occurred. Please try again.";
            }
            setMessages(prev => [...prev, { sender: 'bot', text: errorMessage }]);
        } finally {
            setIsBotResponding(false);
        }
    };
    
    // Function to simulate streaming text
    const simulateStreaming = (fullText) => {
        if (!fullText || typeof fullText !== 'string') {
            console.error('simulateStreaming received invalid input:', fullText);
            return;
        }
    
        const words = fullText.split(' '); // Safely split the string
        let currentText = '';
    
        words.forEach((word, index) => {
            setTimeout(() => {
                currentText += `${word} `;
                setMessages(prev => {
                    const updatedMessages = [...prev];
                    if (updatedMessages[updatedMessages.length - 1]?.sender === 'bot') {
                        updatedMessages[updatedMessages.length - 1].text = currentText.trim();
                    } else {
                        updatedMessages.push({ sender: 'bot', text: currentText.trim() });
                    }
                    return updatedMessages;
                });
            }, index * 100); // Adjust delay as needed for smooth streaming
        });
    
        // Stop streaming when the text is fully displayed
        setTimeout(() => {
            setIsStreaming(false);
        }, words.length * 100);
    };

    
    
    const handleConfirm = () => {
        console.log('Confirmed and proceeding');
        setShowButtons(false);
        setShowSummary(false);
        navigate('/onboardingcampmatch')
        simliClient.close();
    };

    const handleAddDetails = () => {
        console.log('Adding more details');
        setShowButtons(false);
        setIsChatDisabled(false);
        setShowSummary(false);
        
    };

    const handleAddDetailsChat = () => {
        console.log('Adding more details');
        setShowButtons(false);
        setIsChatDisabled(false);
        setShowSummary(false);
        if (isVoiceInputMode){
            startListening();
        }
        
    };

    // useEffect(() => {
    //     const transcript = getTranscript();
    //     if (transcript) {
    //         setInputMessage(transcript);
    //     }
    // }, [getTranscript]);

    // Update inputMessage whenever transcript changes
    // useEffect(() => {
    //     const transcript = getTranscript()
    //     if (transcript && isListening) {
    //         setInputMessage(transcript); 
    //         setTranscribedText(transcript);
    //     }
    // }, [getTranscript, isListening]);

    const handleVoiceInput = (voiceMode = false) => {
        if (isListening) {
            stopListening();
            resetTranscript(); // Clear the transcript after stopping
            if (voiceMode === true) {
                handleSendMessage(true); // Send the message in VoiceMode UI
            }
        } else {
            startListening();
        }
    };

    const messageBubbleStyle = {
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        width: 'fit-content',
    };

    const handleKeyDown = (e) => {
        // Check if Enter key is pressed
        if (e.key === 'Enter') {
          // If Shift key is held down, insert a new line
          if (e.shiftKey) {
            return; // Allow new line to be added
          } else {
            // Otherwise, send the message
            e.preventDefault(); // Prevent default behavior (e.g., form submission or new line)
            handleSendMessage();
          }
        }
    };

    // add here
    const [transcriptTimeout, setTranscriptTimeout] = useState(null);
    const [previousTranscript, setPreviousTranscript] = useState(""); // Track previous transcript
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const handleStart = async() => {
        // Step 1: Start WebRTC
        setIsLoading(true);
        await delay(2000);
        console.log("Starting...");

        try {
            await simliClient.start();
            setStartWebRTC(true);
        } catch (error) {
            console.error('An error occurred:', error.message);
        }
    
        setTimeout(() => {
          // Step 2: Send empty audio data to WebRTC to start rendering
          const audioData = new Uint8Array(1000).fill(0);
          simliClient.sendAudioData(audioData);
        }, 3000);
        // console.log("simli data:", simliClient)
        audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
        return () => {
          if (audioContext.current) {
            audioContext.current.close();
          }
        };
    };
    
    const handleVoiceMode = () => {
        resetTranscript();
        setIsVoiceMode(true);
        setWaitingScreen(true);
        // startListening(); // Automatically start listening when Voice Mode is activated
        initializeSimliClient();
        handleStart();

    };
    
    // const handleCloseVoiceMode = () => {
    //     setIsVoiceMode(false);
    //     setAvatarPlaying(false);
    //     stopListening(); // Automatically stop listening when Voice Mode is closed
    //     simliClient.close();
    //     setStartWebRTC(false);
    //     setIsLoading(false);

    //     handleReload();
    // };

    const handleCloseVoiceMode = () => {
        // setIsVoiceMode(false);
        // setAvatarPlaying(false);
        stopListening(); // Automatically stop listening when Voice Mode is closed
        simliClient.close();
        setStartWebRTC(false);
        // setIsLoading(false);

        // handleReload();
        navigate("/dashboard")
    };

    const [messageSent, setMessageSent] = useState(false);
    const transcriptTimeoutRef = useRef(null);
    // Function to reset the timeout for auto-sending the message
    const resetAutoSendTimeout = () => {
        if (transcriptTimeoutRef.current) {
            clearTimeout(transcriptTimeoutRef.current); // Clear the existing timeout
        }

        transcriptTimeoutRef.current = setTimeout(async () => { // Use async for the callback
            setTranscribedText('');
            if (inputMessage && inputMessage.trim() !== "" && isVoiceMode && !messageSent) { // Only send if there's actual text and not already sent
                try {
                    
                    await handleSendMessage(true); // Wait for handleSendMessage to complete
                    setMessageSent(true); // Set the flag to true after sending
                    console.log("Message sent");
                     // Clear the transcribed text after sending
                    
                } catch (error) {
                    console.error("Error in handleSendMessage:", error);
                }
            }
        }, 2500); // 2.3 second debounce
    };

    // Monitor changes in the transcript and reset timeout
    useEffect(() => {
        const transcript = getTranscript();
        console.log("New transcript:", transcript);
        console.log("Current inputMessage:", inputMessage);
        if (isVoiceMode && isListening) {
            if (transcript !== inputMessage) {
                console.log("Transcript changed, updating inputMessage");
                setInputMessage(transcript); // Update the input message
                setTranscribedText(transcript); // Store transcribed text for UI display
                setMessageSent(false); // Reset the sent flag when transcript changes
            } else if (transcript === inputMessage && !messageSent) {
                console.log("Transcript matches inputMessage, setting timeout");
                // Only reset the timeout if the transcript and inputMessage are the same and message hasn't been sent
                resetAutoSendTimeout();
            }

        } else {
            if (transcript){
                setInputMessage(transcript);
            }
            
        }
    }, [getTranscript, isListening, isVoiceMode, inputMessage, messageSent]);
    
    // useEffect(() => {
    //     // Start listening if VoiceMode is enabled, playback is not happening, and we're not already listening
    //     if (isVoiceMode && showButtons) {
    //         stopListening();
    //     }
    // }, [isVoiceMode, isListening]);

    /**
   * Initializes the Simli client with the provided configuration.
   */
    const initializeSimliClient = useCallback(() => {
        // if (videoRef.current && audioRef.current) {
            const SimliConfig = {
                apiKey: process.env.REACT_APP_SIMLI_API_KEY,
                faceID: process.env.REACT_APP_SIMLI_FACEID_FEMALE,
                handleSilence: true,
                maxSessionLength: 1800, // in seconds
                maxIdleTime: 120, // in seconds
                videoRef: videoRef,
                audioRef: audioRef,
                SimliURL: "s://api.simli.ai"
            };

            // console.log(SimliConfig)

        simliClient.Initialize(SimliConfig);
        console.log("Simli Client initialized");
        // }
    }, []);
    
    const voiceInputModeRef = useRef(isVoiceInputMode);

    useEffect(() => {
        // Sync the ref with the latest state whenever `isVoiceInputMode` changes
        voiceInputModeRef.current = isVoiceInputMode;
    }, [isVoiceInputMode]);

    useEffect(() => {
        
        simliClient.on("connected", () => {
            setIsLoading(false);
            setConnected(true);
            console.log("SimliClient is now connected!");
            
        });
    
        simliClient.on("disconnected", () => {
            setStartWebRTC(false)
            setConnected(false);
            setWaitingScreen(false);
            // setIsVoiceMode(false);
            console.log("SimliClient has disconnected!");

        });
    
        simliClient.on("failed", () => {
            setStartWebRTC(false)
            setConnected(false);
            setWaitingScreen(false);
            setVoiceInputMode(!isVoiceInputMode)
            // setIsVoiceMode(false);
            console.log("SimliClient has failed to connect!");

        });

         // Listen for avatar speaking or silent events
        simliClient.on("speaking", () => {
            setAvatarPlaying(true)
            console.log("Avatar is speaking");
        });

        simliClient.on("silent", () => {
            console.log("Avatar is silent");
            setWaitingScreen(false);
            setAvatarPlaying(false);
            console.log("showButtons:", showButtons);
            // if (showButtons)
            // {
            //     stopListening();
            // } else {
            //     startListening();
            // }
            // else {
            //     if (isVoiceInputMode) {
            //         startListening();
            //     } else {
            //         stopListening();
            //     }
                
            // }

            if (showButtons) {
                stopListening();
            } else {
                if (voiceInputModeRef.current) {
                    startListening();
                } else {
                    stopListening();
                }
            }
            
            // Update state or perform actions when avatar stops speaking
            // Enable mic or any other action
        });

    }, []);

    // useEffect(() => {
    //     if (showButtons) {
    //         stopListening()
    //     } else {
    //         if (isVoiceInputMode) {
    //             startListening();
    //         } else {
    //             stopListening();
    //         }
    //     }

    // }, [isVoiceInputMode]);

    // Function to toggle mute
    const handleMuteToggle = () => {
        if (videoRef.current || audioRef.current) {
            // Toggle muted state for video
            if (videoRef.current) {
                videoRef.current.muted = !isMuted;
                if (!isMuted) videoRef.current.volume = 0; // Set volume to 0 when muting
                else videoRef.current.volume = 1.0; // Restore volume when unmuting
            }
    
            // Toggle muted state for audio
            if (audioRef.current) {
                audioRef.current.muted = !isMuted;
                if (!isMuted) audioRef.current.volume = 0; // Set volume to 0 when muting
                else audioRef.current.volume = 1.0; // Restore volume when unmuting
            }
    
            // Update state
            setIsMuted(!isMuted);
        } else {
            console.warn("Audio or video reference is not available.");
        }
    };

    const handlePause = () => {
        stopListening();
    };

    const handleResume = () => {
        startListening();
    };

    const handleToggleMode = () => {
        if (isVoiceInputMode) {
            stopListening(); // Stop voice input

        } else {
            startListening(); // Start voice input
            console.log("pausing:", isPause)
            
            
        }
        setIsPause(false)
        setVoiceInputMode(!isVoiceInputMode); // Toggle the state
    };

    return (
        <div className="bg-white min-h-screen overflow-auto">

            {/* Voice Mode Overlay */}
            {isVoiceMode && (
                <div className="fixed inset-0 bg-white flex flex-col items-center justify-between z-50">
                    {/* <HeaderMainPage /> */}
                    <div className="w-full h-20 flex justify-center items-center bg-white border-b-4 border-gray-300">
                        <h1 className="text-blue-800 text-sm font-bold">HELP US CUSTOMIZE YOUR EXPERIENCE WITH OUR ASSISTANT!</h1>
                    </div>
                    {/* Flex container for Placeholder and Chat Box */}
                    <div className="flex flex-col md:flex-row w-full flex-1 justify-center items-center space-y-4 md:space-y-0 md:space-x-4 px-4 mr-10">
                        {/* Placeholder with dynamic content */}
                        <div className="flex flex-col justify-center items-center flex-1">
                            <div className="w-[256px] h-[256px] lg:w-[400px] lg:h-[400px] bg-gray-200 rounded-full flex items-center justify-center overflow-hidden">
                            {/* Simli Client Renderer */}
                            <div className="relative w-full h-full">
                                <video
                                    ref={videoRef}
                                    id="simli_video"
                                    autoPlay
                                    playsInline
                                    className="w-full h-full object-cover"
                                ></video>
                                <audio ref={audioRef} id="simli_audio" autoPlay></audio>
                            </div>
                            </div>
                            <button
                                onClick={handleStart}
                                disabled={isConnected && !isLoading} // Disable the button if isConnected is true and isLoading is false
                                className={`mt-4 px-4 py-2 rounded 
                                    ${isConnected && isLoading ? "bg-red-500 text-white cursor-not-allowed" : ""}
                                    ${isConnected && !isLoading ? "bg-green-500 text-white cursor-not-allowed" : ""}
                                    ${!isConnected && isLoading ? "bg-gray-500 text-white cursor-not-allowed" : ""}
                                    ${!isConnected && !isLoading ? "bg-blue-500 text-white hover:bg-blue-600" : ""}
                                `}
                            >
                                {isConnected && isLoading
                                    ? "Error"
                                    : isConnected && !isLoading
                                    ? "Connected"
                                    : !isConnected && isLoading
                                    ? "Connecting..."
                                    : "Start Avatar"}
                            </button>
                        </div>

                        {/* Chat Box */}
                        <div className="flex flex-col w-full md:w-[800px] space-y-4 flex items-center">
                            
                            <div className="overflow-hidden w-full bg-white p-4 rounded-lg shadow-md h-[300px] md:h-[512px] border border-gray">
                                <div className="h-full overflow-y-auto p-4 bg-white scroll-smooth">
                                    {messages.map((message, index) => (
                                        <div
                                            key={index}
                                            className={`flex ${
                                                message.sender === 'user' ? 'justify-end' : 'justify-start'
                                            } mb-4`}
                                        >
                                            <div
                                                className={`flex ${
                                                    message.sender === 'user' ? 'flex-row-reverse' : 'flex-row'
                                                } items-end mb-4`}
                                            >
                                                <div
                                                    className={`w-10 h-10 rounded-full flex-shrink-0 overflow-hidden ${
                                                        message.sender === 'user' ? 'bg-white-100' : 'bg-blue-500'
                                                    }`}
                                                >
                                                    <img
                                                        src={message.sender === 'user' ? profileImage : AIimage}
                                                        alt={message.sender === 'user' ? 'User Avatar' : 'AI Avatar'}
                                                        className="w-full h-full object-cover"
                                                    />
                                                </div>
                                                <div
                                                    className={`mx-4 py-2 px-4 max-w-[90%] md:max-w-[900px] rounded-lg ${
                                                        message.sender === 'user'
                                                            ? 'bg-[#edf1ff] text-black'
                                                            : 'bg-gray-200'
                                                    }`}
                                                    style={messageBubbleStyle}
                                                >
                                                    {message.sender === 'user' ? (
                                                        message.text
                                                    ) : (
                                                        <ReactMarkdown>{message.text}</ReactMarkdown>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <div ref={messagesEndRef} />
                                </div>
                            </div>



                            {/* Display Box / Text Input Box Toggle */}
                            {isVoiceInputMode ? (
                                <div className="w-full h-[100px] p-2 bg-gray-100 rounded-md border border-gray justify-center text-center mb-4">
                                <p className="text-gray-800 text-sm">
                                    {!startWebRTC ? 'Wait till the Avatar starts to talk'
                                    : isAvatarPlaying ? 'AI is Speaking'
                                    : (isListening && !transcribedText) ? 'Listening...'
                                    : transcribedText || 'Waiting...'}
                                </p>
                                </div>
                            ) : (
                                <div className=" w-full flex items-center bg-gray-100 rounded-xl p-2 h-[100px]">
                                <textarea
                                    value={inputMessage}
                                    onChange={(e) => setInputMessage(e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    className="flex-1 bg-transparent outline-none pl-5 resize-none h-50 overflow-y-auto flex justify-center"
                                    placeholder="Type your message..."
                                    disabled={isBotResponding || isChatDisabled || showButtons}
                                    rows={3}
                                />
                                <button
                                    onClick={() => handleSendMessage(isVoiceMode)}
                                    className={`mr-2 ${isBotResponding || isChatDisabled ? 'bg-gray-400' : 'bg-[#223F9E]'} text-white rounded-full p-2`}
                                    disabled={isBotResponding || isChatDisabled || showButtons}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                                    </svg>
                                </button>
                                </div>
                            )}

                            {/* Toggle Button */}
                            <button
                                onClick={() => handleToggleMode()}
                                className={`w-[150px] py-2 text-white rounded-full ${
                                    isBotResponding || isChatDisabled || showButtons
                                        ? 'bg-gray-500 cursor-not-allowed'
                                        : 'bg-blue-400 hover:bg-blue-600'
                                }`}
                                disabled={isBotResponding || isChatDisabled || showButtons}
                            >
                                <span className="font-bold">{isVoiceInputMode ? "TEXT MODE" : "VOICE MODE"}</span>
                            </button>


                            
                        </div>
                    </div>


                    <div className=" relative flex justify-center">
                        {!showButtons && (
                            <div className=" relative flex justify-center space-x-4 mb-2">
                                <button
                                    className={`rounded-full p-4 text-white ${
                                        isListening && isVoiceInputMode ? 'bg-red-500' : 'bg-gray-500'
                                    }`}
                                    disabled={isListening || isAvatarPlaying || showButtons}
                                >
                                    {isListening && isVoiceInputMode ? (
                                        <FaMicrophone size={24} />
                                    ) : (
                                        <FaMicrophoneSlash size={24} />
                                    )}
                                </button>

                                {/* Play/Pause Button */}
                                <button
                                    onClick={() => {
                                        if (isPause) {
                                            handleResume();
                                        } else {
                                            handlePause();
                                        }
                                        setIsPause(!isPause);
                                    }}
                                    disabled={!isVoiceInputMode}
                                    className={`rounded-full p-4 ${
                                        !isVoiceInputMode
                                            ? 'bg-gray-500 cursor-not-allowed' // Gray and indicate disabled state
                                            : isPause
                                            ? 'bg-yellow-500'
                                            : 'bg-green-500'
                                    }`} // Green for Pause, Yellow for Play
                                >
                                    {!isPause ? (
                                        <FaPause size={24} className="text-white" /> // Pause icon
                                    ) : (
                                        <FaPlay size={24} className="text-white" /> // Play icon
                                    )}
                                </button>

                                {/* Mute/Unmute Button */}
                                <button
                                    onClick={handleMuteToggle}
                                    className={`rounded-full p-4 ${isMuted ? 'bg-gray-500' : 'bg-red-500'}`} // Conditional background color
                                >
                                    {isMuted ? (
                                        <FaVolumeMute size={24} className="text-white" /> // Gray when muted
                                    ) : (
                                        <FaVolumeUp size={24} className="text-white" /> // Red when unmuted
                                    )}
                                </button>

                                <button
                                    onClick={handleCloseVoiceMode}
                                    className="bg-red-500 text-white rounded-full p-4"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M6 18L18 6m0 12L6 6"
                                        />
                                    </svg>
                                </button>
                            </div>
                        )}

                        {showButtons && (
                            <div
                            className="relative flex justify-center space-x-4 mb-2">
                            <button
                                onClick={handleConfirm}
                                className="bg-[#223F9E] text-white px-6 py-2 h-[40px] rounded-full font-semibold text-sm"
                            >
                                Confirm and Proceed
                            </button>
                            <button
                                onClick={handleAddDetailsChat}
                                className="bg-green-500 text-white px-6 py-2 h-[40px] rounded-full font-semibold text-sm"
                            >
                                {isVoiceInputMode ? "Continue Speaking" : "Continue Chatting"}
                            </button>
                        </div>
                        )}
                    </div>

                    {/* Additional buttons (appear below with transition) */}
                    {/* {showButtons && (
                        <div
                        className="absolute bottom-0 left-0 right-0 flex justify-center items-center space-x-4 h-[50px] bg-white opacity-100 translate-y-0 transition-all duration-500 ease-in-out"
                        style={{ zIndex: 10 }}
                    >
                        <button
                            onClick={handleConfirm}
                            className="bg-[#223F9E] text-white px-6 py-2 h-[40px] rounded-full font-semibold text-sm"
                        >
                            Confirm and Proceed
                        </button>
                        <button
                            onClick={handleAddDetailsChat}
                            className="bg-green-500 text-white px-6 py-2 h-[40px] rounded-full font-semibold text-sm"
                        >
                            Continue Speaking
                        </button>
                    </div>
                    )} */}
                </div>
            )}

            {/* Waiting Screen Overlay */}
            {isWaitingScreen && (
                <div className="fixed inset-0 bg-white bg-opacity-10W0 text-black flex justify-center items-center z-50">
                <div className="grid grid-cols-1 gap-4 text-center">
                    {/* Spinner in first cell */}
                    <div className="flex justify-center items-center">
                    <FaSpinner className="animate-spin text-8xl text-blue-500" />
                    </div>
                    {/* Text in second cell */}
                    <div className="flex justify-center items-center">
                    <p className="text-2xl font-semibold">ANA is preparing, please wait...</p>
                    </div>
                </div>
                </div>
            )}

            {/* {showButtons && (
                <div className="flex justify-center space-x-4 p-4 bg-white">
                    <button onClick={handleConfirm} className="bg-[#223F9E] text-white px-4 py-2 rounded-full font-semibold text-sm">
                        Confirm and Proceed
                    </button>
                    <button onClick={handleAddDetails} className="bg-green-500 text-white px-4 py-2 rounded-full font-semibold text-sm">
                        Add More Details
                    </button>
                </div>
            )} */}

        </div>
    );
};

export default UserAIChatUI;